import React from 'react';

class Photo extends React.Component {
  componentDidMount() {
    document.title = 'Photo';
  }

  render() {
    return <div>Photo !</div>;
  }
}

export { Photo };
