import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import CustomDialog from '../customDialog';
import WeekPicker from '../weekPicker';
import startOfWeek from 'date-fns/startOfWeek';
import useMobile from '../../hooks/useMobile';

import './index.css';

const WeeklySubmitDialog = (props) => {
  const { isMobile } = useMobile();
  const [formStartOfWeek, setFormStartOfWeek] = useState(startOfWeek(new Date()));
  const [format, setFormat] = useState(null);
  //constant variable to monitor the initial component initialisation and allow the passed in cachedSetting (from the API), to be loaded in
  const [updated, setUpdated] = useState(false);

  var tempLogOut = props.logOut;

  const handleWeekPicker = (day) => {
    setFormStartOfWeek(startOfWeek(day));
  };

  const isSelected = (value) => {
    if (props.cachedSetting !== '' && !updated && !tempLogOut) {
      setFormat(props.cachedSetting);
      setUpdated(true); //Setting the update to false as to block 'if' statement and prevent page from doing an infinite setFormat
    }
    return format === value;
  };

  const handleChange = (event) => {
    setFormat(event.target.value);
  };

  const handleSubmit = () => {
    if (formStartOfWeek && format) {
      props.onClose();
      props.onSubmit(formStartOfWeek, format);
    }
  };

  const createDialogBody = () => {
    return (
      <div className="dialogBody">
        <FormControl>
          <WeekPicker onSelect={handleWeekPicker} />
        </FormControl>
        <FormControl className="formatForm">
          <FormLabel id="buttons-group-label">Select Format</FormLabel>
          <RadioGroup
            row
            aria-labelledby="buttons-group-label"
            defaultValue=""
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel value="EP" control={<Radio />} label="EP" checked={isSelected('EP')} />
            <FormControlLabel
              value="CAST_CREW"
              control={<Radio />}
              label="Cast & Crew"
              checked={isSelected('CAST_CREW')}
            />
          </RadioGroup>
        </FormControl>
        <br />
      </div>
    );
  };

  return (
    <CustomDialog
      fullScreen={isMobile}
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="sm"
      title="Select Week & Format"
      body={createDialogBody()}
      confirmCallback={handleSubmit}
      confirmButtonMessage="Submit"
      cancelButtonMessage="Edit"
      cancelButtonProps={{
        color: 'neutral',
      }}
    />
  );
};

WeeklySubmitDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default WeeklySubmitDialog;
