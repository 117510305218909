import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const TimeFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: timeFormat(values.value),
          },
        });
      }}
      format={timeFormat}
    />
  );
});

TimeFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    // this can happen when the user pastes a number
    if (Number(val) === 0 || val > max) {
      val = '';
    }
  }

  return val;
};

const timeFormat = (val) => {
  if (!val.length) {
    return '';
  }

  //We dont limit the hour value to [0;23], so user can enter value greater than 24 when they finish after midnight
  let hours = val.substring(0, 2);
  let minutes = limit(val.substring(2, 4), '59');

  hours = hours.length ? hours : '';
  if (!hours && minutes.length) {
    minutes = '';
  } else if (hours && minutes.length === 0) {
    minutes = '00';
  } else if (hours && minutes.length === 1) {
    minutes += '0';
  }

  return `${hours}:${minutes}`;
};

export default TimeFormatCustom;
