import React, { useState } from 'react';
import './index.css';
import { Box, Typography, IconButton, Menu, MenuItem } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { logoutUser } from '../../services/logoutService.js';

import MenuListComposition from '../menu';
import NavbarTwo from '../navBarTwo';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const MainContainer = (props) => {
  const [isLoginOut, setIsLoginOut] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleLogout = async (event) => {
    event.preventDefault();
    const { token, bearer, onLogout } = props;
    setIsLoginOut(true);
    await logoutUser(token, bearer);
    onLogout();
  };

  const handleChangeDate = (event, delta) => {
    event.preventDefault();
    const dt = 1000 * 60 * 60 * 24 * delta;
    props.setDtrDateTime(new Date(props.dtrDateTime + dt).getTime());
    props.setMemberDataContainer({});
    props.setShowIconLegend(false);
  };

  const handleDatePickerDateChange = (date) => {
    props.setDtrDateTime(new Date(date).getTime());
    props.setMemberDataContainer({});
    props.setShowIconLegend(false);
  };

  const handleDatePickerOpen = (open) => {
    setDatePickerOpen(open);
  };

  const formatDtrDate = () => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
    }).format(new Date(props.dtrDateTime));
  };

  return (
    <div className="stickyHeader">
      <div className="stickyHeaderUpper">
        <div className="stickyRight" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MenuListComposition
            setNewDTR={props.setNewDTR}
            bearer={props.bearer}
            token={props.token}
            tenantId={props.tenantId}
            setSelectedDepartment={props.setMainSelectedDepartment}
            selectedDepartment={props.mainSelectedDepartment}
            departments={props.departments}
            setIsLoading={props.setIsLoading}
            communityMap={props.communityMap}
            community={props.community}
            setTenantId={props.setTenantId}
            setCommunity={props.setCommunity}
            displayToast={props.displayToast}
            setShowMemberView={props.setShowMemberView}
            setShowTableView={props.setShowTableView}
            handleLogout={handleLogout}
          />
          <Menu
            id="submit-menu"
            MenuListProps={{
              'aria-labelledby': 'submit-btn',
            }}
          >
            <MenuItem>Submit Daily</MenuItem>
            <MenuItem
              sx={{
                textTransform: 'none',
                fontSize: '13px',
                fontFamily: 'Inter-Bold',
                color: 'black',
                borderColor: 'black',
              }}
            >
              Submit Weekly
            </MenuItem>
          </Menu>
        </div>
        <div className="stickyHeaderMid" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            sx={{
              padding: '10px 0',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '24px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {Object.entries(props.community).length > 0
              ? props.community.name === undefined
                ? props.community.toUpperCase()
                : props.community.name
              : null}
          </Typography>
        </div>
      </div>
      <div className="stickyHeaderLower" style={{ margin: '8px 0' }}>
        <div className="dateContainer">
          <IconButton ref={props.backBtn} onClick={(event) => handleChangeDate(event, -1)}>
            <ArrowBackIosNewIcon sx={{ color: 'black', height: '14px' }} />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              open={datePickerOpen}
              onOpen={() => handleDatePickerOpen(true)}
              onClose={() => handleDatePickerOpen(false)}
              value={new Date(props.dtrDateTime)}
              onChange={(newValue) => {
                handleDatePickerDateChange(newValue);
              }}
              renderInput={({ inputRef, inputProps }) => (
                <Box sx={{ display: 'hidden', alignItems: 'center' }}>
                  <a
                    ref={inputRef}
                    {...inputProps}
                    className="dateLink"
                    onClick={(e) => handleDatePickerOpen(true)}
                    style={{ color: 'black', fontFamily: 'Inter', fontSize: '24px' }}
                  >
                    {formatDtrDate()}
                  </a>
                </Box>
              )}
            />
          </LocalizationProvider>
          <IconButton onClick={(event) => handleChangeDate(event, 1)}>
            <ArrowForwardIosIcon sx={{ color: 'black', height: '14px' }} />
          </IconButton>
        </div>
      </div>
      <NavbarTwo
        setShowMemberView={props.setShowMemberView}
        setShowTableView={props.setShowTableView}
        showMemberView={props.showMemberView}
        showTableView={props.showTableView}
      />
    </div>
  );
};

export default MainContainer;
