import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-Q26FP6CNEM'; // GA4 measurement ID

/**
 * Object that can be used to create Google Analytics events in the DTR.
 */

/**
 * Initializes the analytics tracking object with a username and community.
 *
 * @param {String} username of the user to track.
 * @param {String} community of the user to track.
 */
export const initializeAnalytics = (username, community) => {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.set({
    username: username,
    community: community,
  });
};

/**
 * Send a custom event to Google Analytics
 *
 * @param {String} pageName
 * @param {String} action
 */
export const logCustomEventSingleAction = (pageName, action) => {
  ReactGA.event({
    category: pageName,
    action: action,
  });
};
