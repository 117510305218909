import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DataModal from '../dataModal';
import ValueAndEmptyStringValidator from '../../helperFunctions/emptyStringValidator';

const TableDataRow = ({
  itemIndex,
  item,
  showTableView,
  setIsLoading,
  applyToAll,
  applyToNext,
  itemID,
  members,
  bearer,
  token,
  tenantId,
  dtrDateTime,
  memberDataContainer,
  displayToast,
  saveContentFormat,
  IconContainerState,
  setMemberDataContainer,
  width,
}) => {
  let plusSign = false;

  const [tableIndex, setTableIndex] = useState(0);
  const [openDataModal, setOpenDataModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [hideUser, setHideUser] = useState(false);

  let IconContainerStateFiltered = Object.entries(IconContainerState).filter((item) => item[1].use);

  useEffect(() => {
    let memberId = item.id;

    if (memberNotIn(memberId)) {
      setHideUser(true);
    } else {
      setHideUser(false);
    }
  }, [memberDataContainer]);

  // If a member is marked as not-in, hide them in the view so the DTR member filling out the times will not apply them to the not-in member.

  const memberNotIn = (memberId) => {
    if (memberDataContainer.hasOwnProperty(memberId) && memberDataContainer[memberId].hasOwnProperty('call_type')) {
      if (memberDataContainer[memberId].call_type.value === 'not-in') {
        return true;
      }
    }

    return false;
  };

  const handleAddData = (index, tempContent, item) => {
    if (index < 4) {
      setTableIndex(index);
      setOpenDataModal(true);
    } else if (index >= 4) {
      let tempContentFiltered = Object.entries(tempContent).filter((item, itemIndex) => {
        return (
          item[0] !== 'call_type' &&
          IconContainerState[item[0]].use &&
          tempContent[item[0]] !== '-1' &&
          tempContent[item[0]] !== '-'
        );
      });
      let tabs = tempContentFiltered.length + 2;

      if (tempContent['call_time'] === '-1') tabs += 1;
      if (
        tempContent['other_notes'] === '-1' ||
        tempContent['other_notes'] === '-' ||
        tempContent['other_notes'] === ''
      )
        tabs += 1;
      if (tempContent['first_meal_start_time'] === '-1') tabs += 1;
      if (tempContent['first_meal_end_time'] === '-1') tabs += 1;
      if (tempContent['wrap_time'] === '-1') tabs += 1;
      if (
        (tempContent['travel_start_time'] !== '-1' && tempContent['travel_end_time'] === '-1') ||
        (tempContent['travel_start_time'] === '-1' && tempContent['travel_end_time'] !== '-1')
      )
        tabs += 1;
      if (
        (tempContent['second_meal_start_time'] !== '-1' && tempContent['second_meal_end_time'] === '-1') ||
        (tempContent['second_meal_start_time'] === '-1' && tempContent['second_meal_end_time'] !== '-1')
      )
        tabs += 1;

      if (item[0] === 'other_notes') setTableIndex(tabs - 2);
      else if (
        (item[0] === 'travel_start_time' || item[0] === 'travel_end_time') &&
        tempContent['travel_start_time'] === '-1' &&
        tempContent['travel_end_time'] === '-1'
      )
        setTableIndex(4);
      else if (
        item[0] === 'travel_start_time' &&
        (tempContent['travel_start_time'] !== '-1' || IconContainerState['travel_start_time'].use)
      )
        setTableIndex(5);
      else if (
        item[0] === 'travel_end_time' &&
        (tempContent['travel_end_time'] !== '-1' || IconContainerState['travel_end_time'].use)
      )
        setTableIndex(6);
      else if (
        (item[0] === 'second_meal_end_time' || item[0] === 'second_meal_start_time') &&
        tempContent['second_meal_end_time'] === '-1' &&
        tempContent['second_meal_start_time'] === '-1'
      )
        setTableIndex(4);
      else if (
        item[0] === 'second_meal_start_time' &&
        tempContent['second_meal_start_time'] !== '-1' &&
        (!IconContainerState['travel_start_time'].use ||
          (tempContent['travel_start_time'] === '-1' && tempContent['travel_end_time'] === '-1'))
      )
        setTableIndex(5);
      else if (
        item[0] === 'second_meal_end_time' &&
        tempContent['second_meal_end_time'] !== '-1' &&
        (!IconContainerState['travel_end_time'].use ||
          (tempContent['travel_start_time'] === '-1' && tempContent['travel_end_time'] === '-1'))
      )
        setTableIndex(6);
      else if (
        item[0] === 'second_meal_start_time' &&
        tempContent['second_meal_start_time'] !== '-1' &&
        IconContainerState['travel_start_time'].use
      )
        setTableIndex(7);
      else if (
        item[0] === 'second_meal_end_time' &&
        tempContent['second_meal_end_time'] !== '-1' &&
        IconContainerState['travel_end_time'].use
      )
        setTableIndex(8);
      else if (
        (item[0] === 'upgrade_time' && tempContent['upgrade_time'] !== '-1') ||
        tempContent['upgrade_time'] !== '-' ||
        tempContent['upgrade_time'] !== ''
      )
        setTableIndex(tabs - 3);
      else if (
        (item[0] === 'kit_time' && tempContent['kit_time'] !== '-1') ||
        tempContent['kit_time'] !== '-' ||
        (tempContent['kit_time'] !== '' && !IconContainerState['upgrade_time'].use)
      )
        setTableIndex(tabs - 3);
      else if (
        (item[0] === 'kit_time' && tempContent['kit_time'] !== '-1') ||
        tempContent['kit_time'] !== '-' ||
        (tempContent['kit_time'] !== '' && IconContainerState['upgrade_time'].use)
      )
        setTableIndex(tabs - 4);

      setOpenDataModal(true);
    }
  };

  const handleEdit = () => {
    if (!openDataModal) {
      setOpenDataModal(true);
    }

    if (memberDataContainer.hasOwnProperty(item.id)) {
      let trigger = false;

      Object.values(memberDataContainer[item.id]).forEach((obj, index) => {
        if (Object.keys(memberDataContainer[item.id])[index] == 'call_type') {
        } else if (ValueAndEmptyStringValidator(obj)) trigger = true;
      });

      if (trigger) {
        displayToast('Select an icon in the card to edit', false);
        setEdit(true);
      } else {
        displayToast('No cards available to edit', false);
        setEdit(false);
      }
    }
  };

  useEffect(() => {
    IconContainerStateFiltered = Object.entries(IconContainerState).filter((item) => item[1].use);
  }, [IconContainerState]);

  const createRowData = () => {
    let memberId = item.id;
    let tempContent = { ...saveContentFormat };
    let copyContent = {};
    if (memberDataContainer.hasOwnProperty(memberId)) {
      copyContent = memberDataContainer[memberId];
    }

    for (const [key, value] of Object.entries(copyContent)) {
      tempContent[key] = value.value;
    }

    return (
      <>
        {IconContainerStateFiltered.map((item, index) => {
          if ((tempContent[item[0]] === '-1' || tempContent[item[0]] === null) && !plusSign) {
            plusSign = true;
            return (
              <Box
                sx={{
                  width: `${width > 500 ? '15%' : '45px'}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRight: '1px solid #6C6D6B',
                  borderBottom: '1px solid #D9D9D9',
                }}
              >
                <AddIcon sx={{ color: '#01AA25' }} onClick={() => handleAddData(index, tempContent, item)} />
              </Box>
            );
          } else if (
            tempContent[item[0]] === '-1' ||
            tempContent[item[0]] === '-' ||
            tempContent[item[0]] === null ||
            tempContent[item[0]] === ''
          ) {
            return (
              <Box
                sx={{
                  width: `${width > 500 ? '15%' : '45px'}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRight: '1px solid #6C6D6B',
                  borderBottom: '1px solid #D9D9D9',
                }}
              >
                <Typography sx={{}}> - </Typography>
              </Box>
            );
          } else {
            return (
              <Box
                sx={{
                  width: `${width > 500 ? '15%' : '45px'}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: `${
                    tempContent[item[0]] === undefined
                      ? 'center'
                      : tempContent[item[0]].length > 7
                      ? 'flex-start'
                      : 'center'
                  }`,
                  borderRight: '1px solid #6C6D6B',
                  borderBottom: '1px solid #D9D9D9',
                  overflowX: `${width > 500 ? 'hidden' : 'scroll'}`,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => handleAddData(index, tempContent, item)}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                    paddingLeft: `${
                      tempContent[item[0]] === undefined ? '0' : tempContent[item[0]].length > 7 ? '5px' : '0'
                    }`,
                    paddingBottom: 0,
                    overflowX: `${width > 500 ? 'hidden' : 'scroll'}`,
                    whiteSpace: `${width > 500 ? 'nowrap' : ''}`,
                    textOverflow: `${width > 500 ? 'ellipsis' : ''}`,
                  }}
                >
                  {tempContent[item[0]]}
                </Typography>
              </Box>
            );
          }
        })}
        <DataModal
          setIsLoading={setIsLoading}
          item={item}
          applyToAll={applyToAll}
          applyToNext={applyToNext}
          key={itemID}
          itemID={itemID}
          members={members}
          bearer={bearer}
          token={token}
          tenantId={tenantId}
          dtrDateTime={dtrDateTime}
          memberDataContainer={memberDataContainer}
          showTableView={showTableView}
          displayToast={displayToast}
          setOpenDataModal={setOpenDataModal}
          saveContentFormat={saveContentFormat}
          tableIndex={tableIndex}
          setMemberDataContainer={setMemberDataContainer}
          openDataModal={openDataModal}
          edit={edit}
          setEdit={setEdit}
          handleEdit={handleEdit}
        />
      </>
    );
  };

  return !hideUser ? (
    <Box
      sx={{
        display: `${width > 500 ? 'flex' : 'inline-flex'}`,
        backgroundColor: `${itemIndex % 2 !== 0 ? '#DDDDDD42' : '#F9F6F6'}`,
      }}
    >
      <Box
        sx={{
          width: `${width > 500 ? '40%' : '130px'}`,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          borderRight: '1px solid #6C6D6B',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            paddingLeft: '15px',
            paddingTop: '5px',
            paddingBottom: '5px',
            width: 'auto',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {item.firstname} {item.lastname}
        </Typography>
      </Box>
      {createRowData()}
    </Box>
  ) : (
    ''
  );
};

export default TableDataRow;
