import { useState } from 'react';

export default function useCommunity() {
  const getCommunity = () => {
    const communityString = sessionStorage.getItem('community');
    const community = JSON.parse(communityString);
    return communityString ? community : null;
  };

  const saveCommunity = (community) => {
    sessionStorage.setItem('community', JSON.stringify(community));
    setCommunity(community);
  };

  const [community, setCommunity] = useState(getCommunity());

  return {
    setCommunity: saveCommunity,
    community,
  };
}
