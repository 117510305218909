import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import './indexLogin.css';

import { loginUser } from '../../services/loginService';
import LoadingSpinner from '../loadingSpinner';
import config from '../../services/serverOptions';
import Swal from 'sweetalert2';
import $ from 'jquery';

const devEndpoint = config.backendURL;

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const _isMounted = useRef(false);

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const handleSignIn = async (event) => {
    event.preventDefault();

    if (!username.length || !password.length) {
      setError(true);
      return;
    }

    setIsLoading(true);
    const login = await loginUser(username, password);

    if (login) {
      setError(false);
      props.onLogin(login.token, login.tenantId, login.authToken, login.community, login.communitiesIdNameMap);
      sessionStorage.setItem('communityIdMap', JSON.stringify(login.communitiesIdNameMap));
      sessionStorage.setItem('username', JSON.stringify(username));
      sessionStorage.setItem('community', JSON.stringify(login.community));
      document.location.reload();
    } else {
      toast.error('Invalid login', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setPassword('');
      setError(true);
    }

    _isMounted.current && setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Box sx={{ width: 300, mx: 'auto', mt: '32px', display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <div>
          <img
            src={process.env.PUBLIC_URL + '/images/cg-logo-green.png'}
            style={{
              width: '80%',
              paddingTop: '4rem',
              paddingBottom: '5.25rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
            alt="logo"
          />
          {/*<Typography variant="h4" gutterBottom component="div" align="center">
            Drag, Drop, Action!
          </Typography>*/}
          <form onSubmit={handleSignIn}>
            <TextField
              error={error && !username.length}
              name="username"
              label="Username"
              variant="filled"
              fullWidth
              style={{ marginBottom: '12px' }}
              disabled={isLoading}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              helperText={error && !username.length && 'Incorrect entry.'}
            />
            <TextField
              error={error && !password.length}
              name="password"
              label="Password"
              type="password"
              variant="filled"
              fullWidth
              style={{ marginBottom: '12px' }}
              disabled={isLoading}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              helperText={error && !password.length && 'Incorrect entry.'}
            />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              style={{ marginBottom: '12px' }}
              onClick={handleSignIn}
              disabled={isLoading}
            >
              SIGN-IN
            </Button>
            <div
              id="forgotPass"
              onClick={function (e) {
                promptPasswordRecovery();
              }}
            >
              Forgot Password?
            </div>
          </form>
        </div>
      </Box>
      {isLoading ? <LoadingSpinner /> : ''}
    </React.Fragment>
  );
};

Login.prototype = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;

//Function sourced from croogloo-UI login.js (sligly modified)
function promptPasswordRecovery() {
  var emailAddress;
  let recoveryStatus;

  Swal.fire({
    icon: 'info',
    title: 'Password Recovery',
    text: 'Fill in your email address',
    input: 'email',
    inputPlaceholder: 'example@croogloo.com',
    showCancelButton: true,
    confirmButtonText: 'Submit',
    confirmButtonColor: '#13C46A',
    showLoaderOnConfirm: true,
    showCloseButton: true,
    allowOutsideClick: () => !Swal.isLoading(),
    preConfirm: function (email) {
      return new Promise(function (resolve, reject) {
        emailAddress = email;

        $.ajax({
          url: devEndpoint + '/loginservice',
          type: 'post',
          crossDomain: true,
          data: {
            requestType: 'passwordRecovery',
            email: emailAddress,
          },

          success: function (response, status, jqXHR) {
            if (response === 'success') recoveryStatus = 'success';
            resolve();
          },

          error: function (response) {
            recoveryStatus = 'connection error';
          },
        });
      });
    },
  }).then(function (swalStatus) {
    if (
      typeof swalStatus === 'object' &&
      swalStatus !== null &&
      swalStatus.hasOwnProperty('dismiss') &&
      (swalStatus.dismiss === 'cancel' || swalStatus.dismiss === 'close' || swalStatus.dismiss === 'backdrop')
    ) {
      // operation cancelled
      return;
    }

    if (recoveryStatus === 'success') {
      Swal.fire({
        icon: 'success',
        html: '<span style="font-size: 21px">A recovery email has been sent</span>',
        confirmButtonText: 'Got it',
        showCancelButton: false,
        confirmButtonColor: '#13C46A',
        type: 'success',
        allowOutsideClick: true,
      });
    } else if (recoveryStatus !== 'connection error') {
      Swal.fire({
        icon: 'error',
        html:
          '<b>Invalid Email Address</b>' +
          '<br><br>Contact <a style="cursor: pointer; ' +
          'cursor: hand;" href=' +
          '"mailto:support@croogloo.com' +
          '?subject=Lost%20Password">support@croogloo.com' +
          '</a> for assistance',
        confirmButtonColor: '#13C46A',
        showCancelButton: false,
        type: 'error',
      });
    } else {
      Swal.fire({
        icon: 'error',
        html: 'Failed connecting to server.<br>Please try again in a moment.',
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#13C46A',
        showCancelButton: false,
        type: 'error',
      });
    }
  });
}
