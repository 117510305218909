const config = {
  // If using a local backend:
  // backendURL: "http://localhost:8080/",
  //
  // to use the backend deployed and receiving traffic on the dev service:
  // backendURL: 'https://dev.mycroogloo.com/',
  //
  // If developing and using code that is not yet on production uncomment this URL,
  // backendURL: 'https://blah-blah-dot-movieprodmanager.appspot.com/',
  //
  // otherwise use mycroogloo
  backendURL: 'https://www.mycroogloo.com/',
};

export default config;
