import { Box } from '@material-ui/core';
import { Button, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { changePassword } from '../../services/dtrService';
import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import warn from '../../assets/icons/warn.svg';

const ResetPassword = ({ token, tenantId, bearer, setNewUser }) => {
  // this component is copied from the Reset password Modal
  // that is used within the DTR app in the menu

  const displayToast = (message, isError) => {
    toast.error(message, {
      icon: ({ theme, type }) => <img src={warn} />,
      autoClose: 1500,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [oldPassword, setOldPassword] = useState('');
  const [newPasswordOne, setNewPwOne] = useState('');
  const [newPasswordTwo, setNewPwTwo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const showPasswords = (e) => {
    if (e.target.checked) {
      oldPasswordRef.current.type = 'text';
      newPasswordOneRef.current.type = 'text';
      newPasswordTwoRef.current.type = 'text';
    } else {
      oldPasswordRef.current.type = 'password';
      newPasswordOneRef.current.type = 'password';
      newPasswordTwoRef.current.type = 'password';
    }
  };

  const oldPasswordRef = useRef(null);
  const newPasswordOneRef = useRef(null);
  const newPasswordTwoRef = useRef(null);

  const handlePasswordChange = () => {
    if (newPasswordOne !== newPasswordTwo) {
      displayToast('New passwords do not match', true);
      return;
    }

    if (newPasswordOne === '') {
      displayToast('New password length must be greater than one', true);
      return;
    }

    if (newPasswordOne === oldPassword) {
      displayToast('New password cannot equal old password', true);
      return;
    }

    setIsLoading(true);
    changePassword(bearer, token, tenantId, oldPassword, newPasswordOne)
      .then((resp) => {
        setIsLoading(false);
        if (resp.items[0] === 'renew lost password complete') {
          displayToast('Successfully Changed Password', false);
          localStorage.setItem('newUser', false);
          setNewUser(false);
        } else if (resp.items[0] === 'wrong password error') {
          displayToast('Old password entered was incorrect', true);
        } else {
          displayToast('Service not available at this time, please try again later', true);
          console.error('unexpected response: ', resp);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '15px', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ fontWeight: 'bold' }}>RESET PASSWORD</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '7px' }}>
          <label style={{ fontSize: '12px', fontFamily: 'Inter' }}>Type in your old password</label>
          <input
            ref={oldPasswordRef}
            onChange={(e) => setOldPassword(e.target.value)}
            style={{
              height: '30px',
              width: '100%',
              border: '1px solid gray',
              borderRadius: '6px',
              fontFamily: 'Inter',
            }}
            type="password"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '7px' }}>
          <label style={{ fontSize: '12px', fontFamily: 'Inter' }}>Type in your new password</label>
          <input
            ref={newPasswordOneRef}
            onChange={(e) => setNewPwOne(e.target.value)}
            style={{
              height: '30px',
              width: '100%',
              border: '1px solid gray',
              borderRadius: '6px',
              fontFamily: 'Inter',
            }}
            type="password"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '7px' }}>
          <label style={{ fontSize: '12px', fontFamily: 'Inter' }}>Type in your new password again</label>
          <input
            ref={newPasswordTwoRef}
            onChange={(e) => setNewPwTwo(e.target.value)}
            style={{
              height: '30px',
              width: '100%',
              border: '1px solid gray',
              borderRadius: '6px',
              fontFamily: 'Inter',
            }}
            type="password"
          />
        </div>
      </div>
      <div style={{ display: 'flex', columnGap: '4px', fontSize: '12px', fontFamily: 'Inter' }}>
        <input type="checkbox" onChange={(e) => showPasswords(e)} />
        <label>Show passwords?</label>
      </div>
      <Button
        variant="contained"
        sx={{ color: 'white', backgroundColor: '#01AA25', width: '100%' }}
        onClick={handlePasswordChange}
      >
        Submit
      </Button>
    </Box>
  );
};

export default ResetPassword;
