import { Button, Box, Typography, IconButton } from '@mui/material';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import WeekPicker from '../weekPicker';
import startOfWeek from 'date-fns/startOfWeek';
import { saveWTRSetting, submitDailyTimeReport, submitWeeklyTimeReport } from '../../services/dtrService';
import Swal from 'sweetalert2';

import './index.css';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    '&:hover': {
      borderColor: 'black',
      backgroundColor: '#01aa25',
    },
    '&:active': {
      backgroundColor: '#01aa25',
    },
  },
}));

const SubmitButton = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const [weeklyModal, setWeeklyModal] = useState(false);

  const [formStartOfWeek, setFormStartOfWeek] = useState(startOfWeek(new Date()));
  const [format, setFormat] = useState('EP');
  //constant variable to monitor the initial component initialisation and allow the passed in cachedSetting (from the API), to be loaded in
  const [updated, setUpdated] = useState(false);

  const handleWeekPicker = (day) => {
    setFormStartOfWeek(startOfWeek(day));
  };

  useEffect(() => {
    console.debug(formStartOfWeek);
  }, [formStartOfWeek]);

  const isSelected = (value) => {
    if (props.cachedSetting !== '' && !updated) {
      setFormat(props.cachedSetting);
      setUpdated(true); //Setting the update to false as to block 'if' statement and prevent page from doing an infinite setFormat
    }
    return format === value;
  };

  const handleChange = (event) => {
    setFormat(event.target.value);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const submitDTR = () => {
    const { members, memberDataContainer } = props;

    const memberEntries = Object.keys(memberDataContainer);

    let tempMembers = members.map((member) => member.id);
    let missingMembersArray = tempMembers.filter((member) => !memberEntries.includes(member));
    let firstName = null;
    let lastName = null;

    for (const [key, value] of Object.entries(memberDataContainer)) {
      let trigger = false;
      for (const [backendCallValue, backendValue] of Object.entries(value)) {
        if (
          (backendValue.value !== '-1' && backendValue.value !== '-' && backendValue.value !== '') ||
          backendValue.value === 'not-in'
        )
          trigger = true;
      }

      if (!trigger) missingMembersArray.push(key);
    }

    if (missingMembersArray.length == 0) openModal();

    if (missingMembersArray.length >= 1) {
      Swal.fire({
        title: 'MISSING INFO',
        html: `No information was submitted for ${missingMembersArray.length} member${
          missingMembersArray.length === 1 ? '' : 's'
        }. Would you like to edit their times?`,
        confirmButtonText: `Yes, edit contact${missingMembersArray.length === 1 ? '' : 's'}`,
        cancelButtonText: 'No, mark as "Not In"',
        showCloseButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel',
          htmlContainer: 'text-container',
          title: 'text-title',
        },
      })
        .then((result) => {
          console.debug('Result: ', result);
          if (result.isConfirmed) {
          } else if (result.dismiss === 'cancel') {
            console.debug('Marking missing members as not in');
            props.markMissingMembers(missingMembersArray);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleSubmitWeekly = async (startOfWeek, format) => {
    const { bearer, token, tenantId, mainSelectedDepartment } = props;

    setWeeklyModal(false);

    var tempStart = new Date(startOfWeek).getTime();
    var tempEnd = new Date(startOfWeek);

    tempEnd.setDate(tempEnd.getDate() + 7);
    tempEnd = tempEnd.getTime();

    var cont = false;

    var sesionStorageTime = sessionStorage.getItem('dtrSentTime');

    //Checking if there is atleast one DTRs of a departement that has been sent in the week where the user wants to generate a weekly.
    if (props.depDtrArr.length > 0) {
      for (let i of props.depDtrArr) {
        if (i.properties.submitted_time >= tempStart && i.properties.submitted_time < tempEnd) {
          cont = true;
          break;
        }
      }
    }

    //Checking session storage after checking detDtrArr to make sure we can send out DTR
    //We do this steb b/c it is possible for depDtrArr.length > 0 withotu counting the one in session storage
    if (!cont && sesionStorageTime !== null && sesionStorageTime >= tempStart && sesionStorageTime < tempEnd) {
      cont = true;
    }

    if (cont) {
      props.setIsLoading(true);
      const resp = await submitWeeklyTimeReport(
        bearer,
        token,
        tenantId,
        mainSelectedDepartment,
        new Date(startOfWeek).getTime(),
        format
      );
      props.setIsLoading(false);
      const respError = resp.responseCode !== '0';

      if (respError) {
        Swal.fire({
          icon: 'error',
          text: 'Service unavailable try again later',
          confirmButtonColor: '#13C46A',
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        Swal.fire({
          title: 'WEEKLY TIME REPORT SUBMITTED',
          showCloseButton: true,
          showCancelButton: false,
          allowOutsideClick: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn-submit',
            title: 'title-text',
            closeButton: 'close',
            popup: 'popup-container',
          },
          confirmButtonText: 'Ok',
        });
      }
    } else {
      //Swal is fired if no DTR exist in a week where the user wants a WTR
      Swal.fire({
        title: 'NO DAILY TIME REPORTS FOUND',
        html: 'Please submit at least one Daily Report before generating a Weekly Report.',
        showCloseButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-submit',
          popup: 'popup-noreports',
          htmlContainer: 'text',
          closeButton: 'close',
          title: 'title-text',
        },
        confirmButtonText: 'Ok',
      });
    }

    if (props.cachedSetting !== format) {
      //Saving setings only if fomat has changed

      saveWTRSetting(bearer, token, tenantId, format, props.cachedSettingsKey);
    }
  };

  const DTRSubmitted = async () => {
    const { bearer, token, tenantId, mainSelectedDepartment, dtrDateTime, setIsLoading } = props;
    closeModal();
    setIsLoading(true);
    const resp = await submitDailyTimeReport(bearer, token, tenantId, mainSelectedDepartment, dtrDateTime);
    const respError = resp.responseCode !== '0';
    setIsLoading(false);

    if (respError) {
      Swal.fire({
        icon: 'error',
        text: 'Service unavailable try again later',
        confirmButtonColor: '#13C46A',
        showCloseButton: true,
        showCancelButton: false,
      });
    } else {
      Swal.fire({
        title: 'DAILY TIME REPORT SUBMITTED',
        showCloseButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn-submit',
          title: 'title-text',
          closeButton: 'close',
          popup: 'popup-container',
        },
      });
    }
    sessionStorage.setItem('dtrSentTime', dtrDateTime); //Setting flag so that user can imediatly send out WTR without having to refresh page for DB to return a valid DTR
  };

  const customModalStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '230px',
      maxHeight: '150px',
    },
    overlay: {
      zIndex: 1000,
      background: 'rgba(0,0,0,0.5)',
    },
  };

  const customWeeklyModalStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      height: '550px',
    },
    overlay: {
      zIndex: 1000,
      background: 'rgba(0,0,0,0.5)',
    },
  };

  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.buttonStyle}
        sx={{ backgroundColor: '#01AA25', textTransform: 'none', width: '100%', color: 'white' }}
        variant="contained"
        onClick={submitDTR}
      >
        Submit
      </Button>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customModalStyles} ariaHideApp={false}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={closeModal} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
            <Button
              variant="contained"
              sx={{ color: 'white', backgroundColor: '#01AA25', textTransform: 'none', fontFamily: 'Inter-Bold' }}
              onClick={DTRSubmitted}
            >
              Submit Daily
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.buttonStyle}
              onClick={() => {
                setWeeklyModal(true);
                closeModal();
              }}
              sx={{
                textTransform: 'none',
                fontFamily: 'Inter-Bold',
                fontSize: '13px',
                color: 'black',
                borderColor: 'black',
              }}
            >
              Submit Weekly
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        isOpen={weeklyModal}
        onRequestClose={() => setWeeklyModal(false)}
        style={customWeeklyModalStyles}
        ariaHideApp={false}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => setWeeklyModal(false)}>
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            rowGap: '15px',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>SELECT WEEK & FORMAT</Typography>
          <FormControl>
            <WeekPicker onSelect={handleWeekPicker} />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ textAlign: 'center' }}>Select Format</FormLabel>
            <RadioGroup
              row
              aria-labelledby="buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <FormControlLabel value="EP" control={<Radio />} label="EP" checked={isSelected('EP')} />
              <FormControlLabel
                value="CAST_CREW"
                control={<Radio />}
                label="Cast & Crew"
                checked={isSelected('CAST_CREW')}
              />
            </RadioGroup>
          </FormControl>
          <Button
            sx={{ backgroundColor: '#01AA25', textTransform: 'none', width: '80%', color: 'white' }}
            variant="contained"
            onClick={() => handleSubmitWeekly(formStartOfWeek, format)}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SubmitButton;
