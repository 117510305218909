import React from 'react';

import { Typography, Button, Box } from '@mui/material';

import './index.css';

const NavbarTwo = ({ setShowMemberView, setShowTableView, showMemberView, showTableView }) => {
  const selectMemberComponent = () => {
    setShowMemberView(true);
    setShowTableView(false);
  };

  const selectTableComponent = () => {
    setShowMemberView(false);
    setShowTableView(true);
  };

  return (
    <div className="stickyMenu">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          disableRipple
          variant="text"
          size="small"
          sx={{
            color: '#C9C6C6',
            flex: 1,
            fontSize: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifySelf: 'flexStart',
            borderBottom: `${showMemberView ? '3px solid #01aa25' : ''}`,
          }}
          onClick={selectMemberComponent}
          className={`default-button ${showMemberView ? 'selected' : ''}`}
        >
          <Typography variant="p" sx={{ color: '#C9C6C6' }} className={`${showMemberView ? 'selected' : ''}`}>
            Member View
          </Typography>
        </Button>
        <Button
          disableRipple
          variant="text"
          size="small"
          sx={{
            color: '#C9C6C6',
            flex: 1,
            fontSize: '12px',
            display: 'flex',
            flexDirection: 'column',
            borderBottom: `${showTableView ? '3px solid #01aa25' : ''}`,
          }}
          onClick={selectTableComponent}
          className={`default-button ${showTableView ? 'selected' : ''}`}
        >
          <Typography variant="p" sx={{ color: '#C9C6C6' }} className={`${showTableView ? 'selected' : ''}`}>
            Table View
          </Typography>
        </Button>
      </Box>
    </div>
  );
};

export default NavbarTwo;
