import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { addTimeReportPerson, getDepartmentMembers, updateDtrOrdering } from '../../services/dtrService';

const AddCrewMembers = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const addMembersPopup = () => {
    openModal();
  };

  const customModalStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '230px',
      height: 'fit-content',
    },
    overlay: {
      zIndex: 1000,
      background: 'rgba(0,0,0,0.5)',
    },
  };

  const onAddPerson = async () => {
    const { bearer, token, tenantId, setMembers, mainSelectedDepartment, members, displayToast } = props;
    let nameArray = name.split(' ');
    let firstName = '';
    let lastName = '';

    if (nameArray.length === 1) {
      firstName = nameArray[0];

      if (firstName === '') {
        displayToast('Must input first name before adding new member', true);
        return;
      }
    } else if (nameArray.length === 0) {
      displayToast('Must input first name before adding new member', true);
      return;
    } else {
      firstName = nameArray[0];
      nameArray = nameArray.filter((item) => {
        return item !== firstName;
      });
      lastName = nameArray.join(' ');
    }

    if (position === '') {
      displayToast('Must input position before adding new member', true);
      return;
    }

    const resp = await addTimeReportPerson(
      bearer,
      token,
      tenantId,
      mainSelectedDepartment,
      firstName,
      lastName,
      position
    );
    const respError = resp.responseCode !== '0';
    respError ? console.error('Service unavailable try again later') : console.debug('Department Member Added!');

    getDepartmentMembers(bearer, token, tenantId, mainSelectedDepartment, new Date())
      .then((newMembers) => {
        newMembers.items = newMembers.items.filter((item) => item[2] === 'true');
        let newMemberState = newMembers.items.map((item) => props.createData(item));
        newMemberState = newMemberState.filter((item) => {
          let trigger = false;

          members.forEach((secondItem) => {
            if (item.id === secondItem.id) {
              trigger = true;
            }
          });

          if (trigger) return false;
          return true;
        });

        let copyMembers = [...members];
        newMemberState.forEach((item) => copyMembers.push(item));
        setMembers(copyMembers);

        let personOrder = {};
        copyMembers.map((person, index) => {
          personOrder[person.id] = index;
        });

        updateDtrOrdering(bearer, token, tenantId, mainSelectedDepartment, personOrder);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Crew Members
      </Typography>
      <AddCircleIcon sx={{ fontSize: '30px', cursor: 'pointer' }} onClick={addMembersPopup} />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customModalStyles} ariaHideApp={false}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={closeModal} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>ADD CREW MEMBER</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
            <label style={{ fontFamily: 'Inter', fontSize: '13px' }}>Name</label>
            <input
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
              style={{ height: '30px', borderRadius: '6px', border: '1px solid gray', paddingLeft: '8px' }}
              type="text"
            />
            <label style={{ fontFamily: 'Inter', fontSize: '13px' }}>Position</label>
            <input
              value={position}
              required
              onChange={(e) => {
                setPosition(e.target.value);
              }}
              style={{ height: '30px', borderRadius: '6px', border: '1px solid gray', paddingLeft: '8px' }}
              type="text"
            />
          </div>
          <Button
            variant="contained"
            sx={{
              width: '100%',
              backgroundColor: '#01AA25',
              marginTop: '20px',
              marginBottom: '5px',
            }}
            onClick={() => {
              closeModal();
              onAddPerson();
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCrewMembers;
