import { Box, Typography } from '@mui/material';
import './index.css';

import OrderMembersComponentBar from '../../components/orderMembersComponentBar';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { updateDtrOrdering } from '../../services/dtrService';

const OrderMembers = ({
  memberDataContainer,
  members,
  setMembers,
  setMemberDataContainer,
  mainSelectedDepartment,
  bearer,
  token,
  tenantId,
  dtrDateTime,
  saveContentFormat,
  createData,
  setIsLoading,
}) => {
  const [dragging, setDragging] = useState(false);
  const [draggableId, setDraggableId] = useState(null);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(members);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDragging(false);
    setMembers(items);

    const personOrder = {};

    items.map((person, index) => {
      personOrder[person.id] = index;
    });

    updateDtrOrdering(bearer, token, tenantId, mainSelectedDepartment, personOrder)
      .then((resp) => {
        let respSuccess = resp.responseCode === '0';

        respSuccess
          ? console.debug('Successfully updated ordering of users')
          : console.error('Could not update ordering of users');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div style={{ margin: '10px 5px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '12px', fontFamily: 'Inter-Bold' }}>Include In Report</Typography>
        </Box>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={(result) => {
            setDraggableId(result.draggableId);
            setDragging(true);
          }}
        >
          <Droppable droppableId="orderMembers">
            {(provided) => (
              <Box
                pt={2}
                pb={3}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px' }}
                className="orderMembers"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {members.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Box
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        sx={{
                          backgroundColor: `${draggableId === item.id && dragging ? '#F9F6F6' : ''}`,
                          boxShadow: `${draggableId === item.id && dragging ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : ''}`,
                          borderRadius: '8px',
                        }}
                      >
                        <OrderMembersComponentBar
                          dragging={dragging}
                          draggableId={draggableId}
                          memberDataContainer={memberDataContainer}
                          setMemberDataContainer={setMemberDataContainer}
                          item={item}
                          setIsLoading={setIsLoading}
                          bearer={bearer}
                          members={members}
                          setMembers={setMembers}
                          token={token}
                          tenantId={tenantId}
                          dtrDateTime={dtrDateTime}
                          saveContentFormat={saveContentFormat}
                          createData={createData}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Box>
  );
};

export default OrderMembers;
