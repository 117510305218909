import { useState } from 'react';

export default function useTenant() {
  const getTenant = () => {
    const tenantString = sessionStorage.getItem('tenantId');
    const tenantId = JSON.parse(tenantString);
    return tenantString ? tenantId : null;
  };

  const saveTenant = (tenantId) => {
    sessionStorage.setItem('tenantId', JSON.stringify(tenantId));
    setTenantId(tenantId);
  };

  const [tenantId, setTenantId] = useState(getTenant());

  return {
    setTenantId: saveTenant,
    tenantId,
  };
}
