/**
 *  Validates whether a value retrieved from the datastore should be treated as empty.
 *  Checks if `obj` has a `value` property that is not equal to '', null, '-1', or '-'
 *  and returns true is the value is not empty, false otherwise.
 * */

const ValueAndEmptyStringValidator = (obj, property = null) => {
  // these checks are due to inconsisntencies in storing empty values within datastore
  if (property === null) {
    if (obj !== undefined && obj.hasOwnProperty('value')) {
      if (obj.value !== '' && obj.value !== null && obj.value !== '-1' && obj.value !== '-') return true;
    }
    return false;
  }

  if (obj !== undefined && obj.hasOwnProperty(`${property}`) && obj[`${property}`].hasOwnProperty('value')) {
    if (
      obj[`${property}`].value !== '' &&
      obj[`${property}`].value !== null &&
      obj[`${property}`].value !== '-1' &&
      obj[`${property}`].value !== '-'
    )
      return true;
  }

  return false;
};

export default ValueAndEmptyStringValidator;
