import { Typography, Button, Box, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { TravelStart, SecondMealStart, Kit, Upgrade } from '../../assets/icons/iconContainer';

const AdditionalFields = ({
  onSkip,
  setSliderText,
  setNotesText,
  sliderText,
  notesText,
  setIndex,
  memberDataContainer,
  item,
  setEdit,
  index,
}) => {
  const [checked, setChecked] = useState({
    travel_start_time: false,
    travel_end_time: false,
    second_meal_start_time: false,
    second_meal_end_time: false,
    kit_time: false,
    upgrade_time: false,
  });

  const handleCheckedBoxes = () => {
    const newStateSliders = sliderText.map((obj) =>
      obj.id === 4
        ? { ...obj, use: checked.travel_start_time }
        : obj.id === 5
        ? { ...obj, use: checked.travel_end_time }
        : obj.id === 6
        ? { ...obj, use: checked.second_meal_start_time }
        : obj.id === 7
        ? { ...obj, use: checked.second_meal_end_time }
        : obj
    );

    const newStateNotes = notesText.map((obj) =>
      obj.id === 0 ? { ...obj, use: checked.kit_time } : obj.id === 1 ? { ...obj, use: checked.upgrade_time } : obj
    );

    setSliderText(newStateSliders);
    setNotesText(newStateNotes);
    setEdit(false);

    if (checked.travel_start_time || checked.second_meal_start_time || checked.kit_time || checked.upgrade_time) {
      setIndex(5);
    } else {
      setIndex(6);
    }
    // let circleTabIndex = newStateSliders.filter((obj) => (obj.use)).length
  };

  useEffect(() => {
    let memberId = item.id;

    if (memberDataContainer.hasOwnProperty(memberId)) {
      let desiredContent = memberDataContainer[memberId];

      const newCheckedState = {
        travel_start_time: false,
        travel_end_time: false,
        second_meal_start_time: false,
        second_meal_end_time: false,
        kit_time: false,
        upgrade_time: false,
      };

      const newSliderTextState = sliderText.map((obj) => {
        // if (travel && obj.backendCallValue === 'travel_end_time') return { ...obj, use: true };
        // if (meal && obj.backendCallValue === 'second_meal_end_time') return { ...obj, use: true };
        if (
          checked.hasOwnProperty(obj.backendCallValue) &&
          desiredContent.hasOwnProperty(obj.backendCallValue) &&
          desiredContent[obj.backendCallValue].hasOwnProperty('value')
        ) {
          if (
            desiredContent[obj.backendCallValue].value !== '-1' &&
            desiredContent[obj.backendCallValue].value !== '-'
          ) {
            newCheckedState[obj.backendCallValue] = true;

            return { ...obj, use: true };
          }
        }
        return obj;
      });

      const modifiedTextState = newSliderTextState.map((obj) => {
        if (obj.backendCallValue === 'travel_start_time' || obj.backendCallValue === 'travel_end_time') {
          if (newCheckedState.travel_end_time || newCheckedState.travel_start_time) {
            return { ...obj, use: true };
          }
        } else if (
          obj.backendCallValue === 'second_meal_start_time' ||
          obj.backendCallValue === 'second_meal_end_time'
        ) {
          if (newCheckedState.second_meal_end_time || newCheckedState.second_meal_start_time) {
            return { ...obj, use: true };
          }
        }
        return obj;
      });

      const newNotesTextState = notesText.map((obj) => {
        if (
          checked.hasOwnProperty(obj.backendCallValue) &&
          desiredContent.hasOwnProperty(obj.backendCallValue) &&
          desiredContent[obj.backendCallValue].hasOwnProperty('value')
        ) {
          if (
            desiredContent[obj.backendCallValue].value !== '-1' &&
            desiredContent[obj.backendCallValue].value !== '-'
          ) {
            newCheckedState[obj.backendCallValue] = true;
            return { ...obj, use: true };
          }
        }
        return obj;
      });

      setChecked(newCheckedState);
      setSliderText(modifiedTextState);
      setNotesText(newNotesTextState);
    }
  }, [memberDataContainer]);

  useEffect(() => {
    const newCheckedState = {
      travel_start_time: false,
      travel_end_time: false,
      second_meal_start_time: false,
      second_meal_end_time: false,
      kit_time: false,
      upgrade_time: false,
    };

    sliderText.forEach((obj) => {
      if (newCheckedState.hasOwnProperty(obj.backendCallValue) && obj.use) {
        newCheckedState[obj.backendCallValue] = true;
      }
    });

    notesText.forEach((obj) => {
      if (newCheckedState.hasOwnProperty(obj.backendCallValue) && obj.use) {
        newCheckedState[obj.backendCallValue] = true;
      }
    });

    setChecked(newCheckedState);
  }, [sliderText, notesText]);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '15px',
          marginTop: '10px',
        }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: '12.5px', textAlign: 'center', display: 'block', fontFamily: 'Inter-Bold' }}
        >
          Select Additional Fields To Include on DTR
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton onClick={() => setIndex(index - 1)}>
            <ChevronLeftIcon sx={{ color: '#01aa25' }} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '5px',
            }}
          >
            <input
              checked={checked.travel_start_time || checked.travel_end_time}
              onChange={(cb) => {
                setChecked((prev) => ({
                  ...prev,
                  travel_start_time: cb.target.checked,
                  travel_end_time: cb.target.checked,
                }));
              }}
              style={{ height: '16px', width: '16px' }}
              type="checkbox"
            />
            <TravelStart />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '5px',
            }}
          >
            <input
              checked={checked.second_meal_start_time || checked.second_meal_end_time}
              onChange={(cb) => {
                setChecked((prev) => ({
                  ...prev,
                  second_meal_start_time: cb.target.checked,
                  second_meal_end_time: cb.target.checked,
                }));
              }}
              style={{ height: '16px', width: '16px' }}
              type="checkbox"
            />
            <SecondMealStart />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '5px',
            }}
          >
            <input
              checked={checked.kit_time}
              onChange={(cb) => {
                setChecked((prev) => ({ ...prev, kit_time: cb.target.checked }));
              }}
              style={{ height: '16px', width: '16px' }}
              type="checkbox"
            />
            <Kit />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: '5px',
            }}
          >
            <input
              checked={checked.upgrade_time}
              onChange={(cb) => {
                setChecked((prev) => ({ ...prev, upgrade_time: cb.target.checked }));
              }}
              style={{ height: '16px', width: '16px' }}
              type="checkbox"
            />
            <Upgrade />
          </Box>
          <IconButton onClick={() => setIndex(index + 1)}>
            <ChevronRightIcon sx={{ color: '#01aa25' }} />
          </IconButton>
        </Box>
      </Box>
      <Box pb={1} sx={{ display: 'flex', justifyContent: 'center', columnGap: '35px' }}>
        <Button
          disableRipple
          sx={{
            width: '120px',
            color: 'black',
            textTransform: 'none',
            border: '1px solid black',
            height: '34px',
            fontFamily: 'Inter-Bold',
          }}
          onClick={() => onSkip(true)}
        >
          Skip
        </Button>
        <Button
          disableRipple
          sx={{
            width: '120px',
            color: 'white',
            textTransform: 'none',
            backgroundColor: 'black',
            '&:hover': { backgroundColor: 'black' },
            height: '34px',
            fontFamily: 'Inter-Bold',
          }}
          onClick={handleCheckedBoxes}
        >
          Add Fields
        </Button>
      </Box>
    </Box>
  );
};

export default AdditionalFields;
