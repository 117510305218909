import { useState } from 'react';

export default function useBearer() {
  const getBearer = () => {
    const bearerString = sessionStorage.getItem('bearer');
    const bearer = JSON.parse(bearerString);
    return bearerString ? bearer : null;
  };

  const saveBearer = (bearer) => {
    sessionStorage.setItem('bearer', JSON.stringify(bearer));
    setBearer(bearer);
  };

  const [bearer, setBearer] = useState(getBearer());

  return {
    setBearer: saveBearer,
    bearer,
  };
}
