import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Modal = (props) => {
  const modalRef = useRef();

  useEffect(() => {
    const clickOutsideContent = (e) => {
      if (e.target === modalRef.current) {
        props.setShow(false);
      }
    };
    window.addEventListener('click', clickOutsideContent);
    return () => {
      window.removeEventListener('click', clickOutsideContent);
    };
  }, [props]);

  return (
    <div ref={modalRef} className={`modal ${props.show ? 'active' : ''} ${props.styleClass}`}>
      <div className="modal__content">
        <span onClick={() => props.setShow(false)} className="modal__close">
          &times;
        </span>
        {props.children}
      </div>
    </div>
  );
};

Modal.prototype = {
  styleClass: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default Modal;

export const ModalHeader = (props) => {
  return <div className="modal__header">{props.children}</div>;
};

export const ModalBody = (props) => {
  return <div className="modal__body">{props.children}</div>;
};

export const ModalFooter = (props) => {
  return <div className="modal__footer">{props.children}</div>;
};
