import Api from './api.js';

export async function logoutUser(token, authToken) {
  const payload = {
    token,
    authToken,
    isInactivityTimeout: false,
    xsrf: '8f869fd8b51bcdd4173168f17986d499f8e70ba9',
  };

  const resp = await Api.doLoginPost('logoutservice', payload);

  return resp;
}
