import config from './serverOptions';

export default class Api {
  static SERVER_URL = config.backendURL;

  static doGet = (route, bearer, params) => {
    let paramsBody = [];
    for (let props in params) {
      paramsBody.push(`${props}=${params[props]}`);
    }
    paramsBody = paramsBody.length > 0 ? '?' + paramsBody.join('&') : '';

    return fetch(Api.SERVER_URL + route + paramsBody, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + bearer,
        Accept: '*/*',
      },
      mode: 'cors',
    })
      .then((resp) => resp.json())
      .then((json) => json)
      .catch((err) => {
        console.error(err);
        return null;
      });
  };

  static doDelete = (route, bearer, params, payload) => {
    return this._doSend('DELETE', route, bearer, params, payload);
  };

  static doPost = (route, bearer, params, payload) => {
    return this._doSend('POST', route, bearer, params, payload);
  };

  static doPut = (route, bearer, params, payload) => {
    return this._doSend('PUT', route, bearer, params, payload);
  };

  static _doSend(method, route, bearer, params, payload) {
    let paramsBody = [];
    for (let props in params) {
      paramsBody.push(`${props}=${params[props]}`);
    }
    paramsBody = paramsBody.length > 0 ? '?' + paramsBody.join('&') : '';

    return fetch(Api.SERVER_URL + route + paramsBody, {
      method: method,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: 'Bearer ' + bearer,
        Accept: '*/*',
      },
      mode: 'cors',
      body: payload ? JSON.stringify(payload) : '',
    })
      .then((resp) => resp.json())
      .then((json) => json)
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  static doLoginPost = (method, payload) => {
    let formBody = [];
    //Transform object into x-www-form-urlencoded content-type
    for (let props in payload) {
      let encodedKey = encodeURIComponent(props);
      let encodedValue = encodeURIComponent(payload[props]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    formBody = formBody.join('&');

    return fetch(Api.SERVER_URL + method, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Accept: '*/*',
      },
      mode: 'cors',
      body: formBody,
    })
      .then((resp) => resp.text())
      .then((data) => (data ? JSON.parse(data) : {}))
      .catch((err) => {
        console.error(err);
        return null;
      });
  };
}
