import CircleIcon from '@mui/icons-material/Circle';

import { Box, Typography, IconButton } from '@mui/material';
import { useState, useEffect } from 'react';

import GenericSlider from '../genericSlider';
import AdditionalFields from '../additionalFields';
import NotesSlider from '../notesSlider';
import FinalConfirmSlider from '../finalConfirmSlider';
import SwipeableViews from 'react-swipeable-views';
import Modal from 'react-modal';
import RemoveIcon from '@mui/icons-material/Remove';

import {
  CallTime,
  MealStart,
  MealEnd,
  TravelStart,
  TravelEnd,
  Wrap,
  SecondMealStart,
  SecondMealEnd,
  Kit,
} from '../../assets/icons/iconContainer';
import ValueAndEmptyStringValidator from '../../helperFunctions/emptyStringValidator';

const DataModal = ({
  applyToAll,
  applyToNext,
  itemID,
  item,
  bearer,
  token,
  tenantId,
  dtrDateTime,
  memberDataContainer,
  showTableView,
  displayToast,
  saveContentFormat,
  setMemberDataContainer,
  tableIndex,
  setConfirm,
  handleEdit,
  edit,
  setEdit,
  setOpenDataModal,
  openDataModal,
  handleModalClose,
}) => {
  const DEFAULT_TABS = 7;

  const [index, setIndex] = useState(0);
  const [tabs, setTabs] = useState(DEFAULT_TABS);

  const customAddDataModalStyles = {
    content: {
      padding: '10px 5px',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: `${index === tabs - 1 ? 'visible' : 'hidden'}`,
      width: '310px',
      height: `${index === tabs - 1 ? 'fit-content' : '230px'}`,
      backgroundColor: '#F9F6F6',
      border: '2px solid #01AA25',
      borderRadius: '12px',
    },
    overlay: {
      zIndex: 10,
      background: 'rgba(0,0,0,0.5)',
    },
  };

  const onSkip = (skipped) => {
    if (skipped) {
      setIndex(index + 1);
    }
  };

  const [sliderText, setSliderText] = useState([
    { id: 0, title: 'Call Time', use: true, backendCallValue: 'call_time', component: <CallTime /> },
    {
      id: 1,
      title: 'Meal Start',
      use: true,
      backendCallValue: 'first_meal_start_time',
      component: <MealStart />,
    },
    {
      id: 2,
      title: 'Meal End',
      use: true,
      backendCallValue: 'first_meal_end_time',
      component: <MealEnd edit={edit} />,
    },
    { id: 3, title: 'Wrap', use: true, backendCallValue: 'wrap_time', component: <Wrap /> },
    {
      id: 4,
      title: 'Travel Start',
      use: false,
      backendCallValue: 'travel_start_time',
      component: <TravelStart />,
    },
    {
      id: 5,
      title: 'Travel End',
      use: false,
      backendCallValue: 'travel_end_time',
      component: <TravelEnd />,
    },
    {
      id: 6,
      title: '2nd Meal Start',
      use: false,
      backendCallValue: 'second_meal_start_time',
      component: <SecondMealStart />,
    },
    {
      id: 7,
      title: '2nd Meal End',
      use: false,
      backendCallValue: 'second_meal_end_time',
      component: <SecondMealEnd />,
    },
    { id: 8, title: 'Kit', use: false, backendCallValue: 'kit_time', component: <Kit /> },
  ]);

  const [notesText, setNotesText] = useState([
    { id: 0, title: 'Kit', use: false, backendCallValue: 'kit_time' },
    { id: 1, title: 'Upgrade', use: false, backendCallValue: 'upgrade_time' },
    { id: 2, title: 'Notes', use: true, backendCallValue: 'other_notes' },
  ]);

  useEffect(() => {
    let totalTabs = 0;

    sliderText.forEach((obj) => {
      if (obj.use && obj.title !== 'Kit') totalTabs += 1;
    });

    notesText.forEach((obj) => {
      if (obj.use) totalTabs += 1;
    });

    setTabs(totalTabs + 2);
  }, [sliderText, notesText]);

  const formatTimeValues = () => {
    let newStateContainer = structuredClone(memberDataContainer);
    if (newStateContainer.hasOwnProperty(item.id)) {
      for (let [key, value] of Object.entries(newStateContainer[item.id])) {
        if (key !== 'call_type' && key !== 'other_notes' && key !== 'kit_time' && key !== 'upgrade_time') {
          if (ValueAndEmptyStringValidator(value)) {
            let hours = value.value.split(':')[0];
            let minutes = value.value.split(':')[1];

            if (hours.length === 0) hours = '00';
            else if (hours.length === 1) hours = `${hours}0`;

            if (minutes.length === 0) minutes = '00';
            else if (minutes.length === 1) minutes = `${minutes}0`;

            newStateContainer[item.id][key].value = `${hours}:${minutes}`;
          }
        }
      }

      setMemberDataContainer(newStateContainer);
    }
  };

  const onConfirm = async () => {
    setConfirm(true);
    setEdit(false);
    displayToast('Successfully confirmed', false);
  };

  let tabsArray = Array.from(Array(tabs).keys());
  let notesTextFiltered = notesText.filter((obj) => obj.use);
  let sliderTextFiltered = sliderText.filter((obj) => obj.use && obj.title !== 'Kit' && obj.id <= 3);
  let additionalFieldsFiltered = sliderText.filter((obj) => obj.use && obj.title !== 'Kit' && obj.id > 3);

  const createCircleTabs = () =>
    tabsArray.map((keyNum) => (
      <CircleIcon
        onClick={() => {
          if (keyNum > tabs) {
            keyNum = tabs;
          }
          setIndex(keyNum);
        }}
        key={keyNum}
        sx={{
          color: `${keyNum === index ? '#01AA25' : '#D9D9D9'}`,
          fontSize: 'small',
          padding: '0 3px',
          cursor: 'pointer',
        }}
      />
    ));

  useEffect(() => {
    if (edit) {
      setIndex(tabs - 1);
    }
  }, [tabs, edit]);

  useEffect(() => {
    if (showTableView) {
      setIndex(tableIndex);
    }
  }, [tableIndex]);

  return (
    <Modal
      isOpen={openDataModal}
      onRequestClose={() => {
        if (showTableView) {
          setOpenDataModal(false);
        } else {
          handleModalClose();
        }
      }}
      style={customAddDataModalStyles}
      ariaHideApp={false}
    >
      <Box pb={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            fontSize: '13px',
            paddingLeft: '8px',
            paddingTop: '5px',
            width: 'auto',
            maxWidth: '70%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          <Typography sx={{ fontFamily: 'Inter-bold', fontSize: '13px' }} variant="span">
            {item.firstname} {item.lastname} -{' '}
          </Typography>{' '}
          {item.department}
        </Typography>
        <IconButton
          onClick={() => {
            if (showTableView) {
              formatTimeValues();
              setOpenDataModal(false);
            } else {
              formatTimeValues();
              handleModalClose();
            }
          }}
        >
          <RemoveIcon sx={{ color: '#01AA25', fontSize: '16px' }} />
        </IconButton>
      </Box>
      <Box>
        <Box mb={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {createCircleTabs()}
        </Box>
        <SwipeableViews enableMouseEvents index={index} onChangeIndex={(key) => setIndex(key)}>
          {sliderTextFiltered.map((obj, ind) => (
            <GenericSlider
              backendCallValue={obj.backendCallValue}
              bearer={bearer}
              token={token}
              tenantId={tenantId}
              dtrDateTime={dtrDateTime}
              item={item}
              applyToNext={applyToNext}
              applyToAll={applyToAll}
              text={obj.title}
              key={ind}
              itemID={itemID}
              saveContentFormat={saveContentFormat}
              memberDataContainer={memberDataContainer}
              setMemberDataContainer={setMemberDataContainer}
              index={index}
              setIndex={setIndex}
              tabs={tabs}
            />
          ))}
          <AdditionalFields
            setEdit={setEdit}
            onSkip={onSkip}
            setSliderText={setSliderText}
            setNotesText={setNotesText}
            sliderText={sliderText}
            notesText={notesText}
            setIndex={setIndex}
            memberDataContainer={memberDataContainer}
            item={item}
            index={index}
          />
          {additionalFieldsFiltered.map((obj, ind) => (
            <GenericSlider
              backendCallValue={obj.backendCallValue}
              bearer={bearer}
              token={token}
              tenantId={tenantId}
              dtrDateTime={dtrDateTime}
              item={item}
              applyToNext={applyToNext}
              applyToAll={applyToAll}
              text={obj.title}
              key={ind}
              itemID={itemID}
              saveContentFormat={saveContentFormat}
              memberDataContainer={memberDataContainer}
              setMemberDataContainer={setMemberDataContainer}
              index={index}
              setIndex={setIndex}
              tabs={tabs}
            />
          ))}
          {notesTextFiltered.map((obj, ind) => (
            <NotesSlider
              backendCallValue={obj.backendCallValue}
              bearer={bearer}
              token={token}
              tenantId={tenantId}
              dtrDateTime={dtrDateTime}
              item={item}
              applyToNext={applyToNext}
              applyToAll={applyToAll}
              text={obj.title}
              key={ind}
              itemID={itemID}
              saveContentFormat={saveContentFormat}
              memberDataContainer={memberDataContainer}
              notesText={notesText}
              setMemberDataContainer={setMemberDataContainer}
              index={index}
              setIndex={setIndex}
            />
          ))}
          <FinalConfirmSlider
            tabs={tabs}
            item={item}
            edit={edit}
            itemID={itemID}
            memberDataContainer={memberDataContainer}
            handleEdit={handleEdit}
            onConfirm={onConfirm}
            sliderText={sliderText}
            notesText={notesText}
            setIndex={setIndex}
            index={index}
            handleModalClose={handleModalClose}
            showTableView={showTableView}
            setOpenDataModal={setOpenDataModal}
            formatTimeValues={formatTimeValues}
          />
        </SwipeableViews>
      </Box>
    </Modal>
  );
};

export default DataModal;
