import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import MainContainer from '../../components/mainContainer';
import SubmitButton from '../../components/submitButton';
import {
  fetchDailyTimeReportNote,
  getDepartmentForUI,
  getUserDepartment,
  getDepartmentMembers,
  fetchDTR,
  updateDailyTimeReportNote,
  getWTRSetting,
  getDailyTimeLogs,
  saveDailyTimeLogs,
} from '../../services/dtrService';

import OrderMembers from '../orderMembers';
import './index.css';

import warn from '../../assets/icons/warn.svg';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import AddCrewMembers from '../../components/addCrewMembers';
import IconLegend from '../../components/iconLegend';
import LoadingSpinner from '../../components/loadingSpinner';
import TableDataRow from '../../components/tableDataRow';
import { IconContainer } from '../../assets/icons/iconContainer';
import MemberDataRow from '../../components/memberDataRow';
import ValueAndEmptyStringValidator from '../../helperFunctions/emptyStringValidator';

const DailyTimeReportV2 = ({
  bearer,
  token,
  tenantId,
  community,
  onLogout,
  communityMap,
  setTenantId,
  setCommunity,
  setNewDTR,
}) => {
  const [members, setMembers] = useState([]);
  const [mainSelectedDepartment, setMainSelectedDepartment] = useState(null);
  const [dtrDateTime, setDtrDateTime] = useState(new Date().getTime());
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState({ items: [] });
  const [depDtrArr, setDepDtrArr] = useState([]);
  const [departmentNotes, setDepartmentNotes] = useState('');
  const [memberDataContainer, setMemberDataContainer] = useState({});
  const [IconContainerState, setIconContainerState] = useState({ ...IconContainer });
  const [showMemberView, setShowMemberView] = useState(true);
  const [showTableView, setShowTableView] = useState(false);
  const [showIconLegend, setShowIconLegend] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const backBtn = useRef(null);

  const saveContentFormat = {
    call_time: '-1',
    first_meal_start_time: '-1',
    first_meal_end_time: '-1',
    wrap_time: '-1',
    travel_start_time: '-1',
    travel_end_time: '-1',
    second_meal_start_time: '-1',
    second_meal_end_time: '-1',
    kit_time: '-1',
    call_type: 'weekly',
    upgrade_time: '-1',
    other_notes: '-1',
  };

  var cachedSettingsKey = ''; //Key of the setting choice of the user for the WTR
  var currFormat = ''; //Current CASHED format for WTR

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //Method verifies if the currently selected departement has DTRs submitted for the previous day, in case of overnight shoots
  const verifyDTRprevDaySubmision = (dtrArray) => {
    let today = new Date(dtrDateTime);
    today.setHours(0, 0, 0, 0);
    let prevDate = new Date(dtrDateTime);
    prevDate.setDate(today.getDate() - 1);
    prevDate.setHours(0, 0, 0, 0);

    let tempArr = dtrArray.filter((dtr) => {
      return dtr.properties.target_report_time >= prevDate.getTime();
    });

    return new Promise((accept) => {
      let showDtrSwitchSwal = !localStorage.getItem('showDtrSwitchSwal');

      if (showDtrSwitchSwal) {
        Swal.fire({
          title: 'Switch To Old Design',
          html: "If you'd like to switch to old design you can do so in settings.",
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn cancel',
            htmlContainer: 'text-container',
            title: 'text-title',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then(() => {
          localStorage.setItem('showDtrSwitchSwal', true);
          accept();
        });
      } else accept();
    }).then(() => {
      if (tempArr.length === 0) {
        Swal.fire({
          title: 'Missing DTR',
          html:
            'No DTRs were found for <b>' +
            prevDate.toDateString() +
            '</b>. Would you like to submit the hours for that day?',
          confirmButtonText: 'Yes, change the date',
          cancelButtonText: 'No, leave this date',
          showCancelButton: true,
          allowOutsideClick: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn confirm',
            cancelButton: 'btn cancel',
            htmlContainer: 'text-container',
            title: 'text-title',
          },
        })
          .then((result) => {
            if (result.isConfirmed) {
              if (backBtn.current !== undefined && backBtn.current !== null) {
                backBtn.current.click();
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };

  // Member & Table View Section #################################################################

  const applyToAll = (time, backendCallValue, title) => {
    displayToast(`${title} Applied to All`, false);

    let newStateContainer = {};

    members.forEach((item) => {
      let memberId = item.id;

      let copyContent = {};
      if (memberDataContainer.hasOwnProperty(memberId)) copyContent = memberDataContainer[memberId];

      let tempContent = { ...saveContentFormat };
      if (!memberId.startsWith('PERSON_')) tempContent.call_type = 'daily';

      for (const [key, value] of Object.entries(copyContent)) {
        tempContent[key] = copyContent[key].value;
      }

      if (tempContent.kit_time === '-1') tempContent.kit_time = '-';
      if (tempContent.upgrade_time === '-1') tempContent.upgrade_time = '-';

      tempContent[backendCallValue] = time;

      let storageContent = {};
      Object.keys(tempContent).forEach((item) => {
        storageContent[item] = { value: tempContent[item] };
      });

      newStateContainer[memberId] = storageContent;

      saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, memberId, tempContent)
        .then((resp) => {
          resp.responseCode === '0'
            ? console.debug('Successfully saved user data')
            : console.error('Could not save user data');
        })
        .catch((err) => {
          console.error(err);
        });
    });

    setMemberDataContainer(newStateContainer);
  };

  const applyToNext = (time, backendCallValue, nextID, title) => {
    displayToast(`${title} Applied to Next Card`, false);

    let memberId = members[nextID].id;
    let memberIdNext = members[nextID + 1].id;

    let copyContent = {};
    if (memberDataContainer.hasOwnProperty(memberId)) copyContent = memberDataContainer[memberId];

    let copyContentNext = {};
    if (memberDataContainer.hasOwnProperty(memberIdNext)) copyContentNext = memberDataContainer[memberIdNext];

    let tempContent = { ...saveContentFormat };
    if (!memberId.startsWith('PERSON_')) tempContent.call_type = 'daily';

    let tempContentNext = { ...saveContentFormat };
    if (!memberIdNext.startsWith('PERSON_')) tempContentNext.call_type = 'daily';

    let newStateContainer = { ...memberDataContainer };

    for (const [key, value] of Object.entries(copyContent)) {
      tempContent[key] = copyContent[key].value;
    }

    for (const [key, value] of Object.entries(copyContentNext)) {
      tempContentNext[key] = copyContentNext[key].value;
    }

    if (tempContent.kit_time === '-1') tempContent.kit_time = '-';
    if (tempContent.upgrade_time === '-1') tempContent.upgrade_time = '-';

    if (tempContentNext.kit_time === '-1') tempContentNext.kit_time = '-';
    if (tempContentNext.upgrade_time === '-1') tempContentNext.upgrade_time = '-';

    tempContent[backendCallValue] = time;
    tempContentNext[backendCallValue] = time;

    let storageContent = {};
    Object.keys(tempContent).forEach((item) => {
      storageContent[item] = { value: tempContent[item] };
    });

    let storageContentNext = {};
    Object.keys(tempContentNext).forEach((item) => {
      storageContentNext[item] = { value: tempContentNext[item] };
    });

    newStateContainer[memberId] = storageContent;
    newStateContainer[memberIdNext] = storageContentNext;

    saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, memberId, tempContent)
      .then((resp) => {
        resp.responseCode === '0'
          ? console.debug('Successfully saved user data')
          : console.error('Could not save user data');
      })
      .catch((err) => {
        console.error(err);
      });

    saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, memberIdNext, tempContentNext)
      .then((resp) => {
        resp.responseCode === '0'
          ? console.debug('Successfully saved user data')
          : console.error('Could not save user data');
      })
      .catch((err) => {
        console.error(err);
      });

    setMemberDataContainer(newStateContainer);
  };

  const displayToast = (message, isError) => {
    toast.error(message, {
      icon: ({ theme, type }) => <img src={warn} />,
      autoClose: 1500,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const createData = (row) => {
    if (row[6] && row[7]) {
      return {
        id: row[0],
        name: row[1],
        isDisplay: row[2] === 'true',
        firstname: row[3],
        lastname: row[4],
        department: row[5],
        deletedDate: row[6],
        dateToPermanentlyRemove: row[7],
      };
    } else {
      return {
        id: row[0],
        name: row[1],
        isDisplay: row[2] === 'true',
        firstname: row[3],
        lastname: row[4],
        department: row[5],
      };
    }
  };

  const fetchUserData = async (members) => {
    let promiseArray = [];
    const reflect = (p) => p.then((resp) => resp);

    members.forEach((item, index) => {
      let memberId = item.id;
      promiseArray.push(getDailyTimeLogs(bearer, token, tenantId, dtrDateTime, memberId));
    });

    Promise.all(promiseArray.map(reflect))
      .then((res) => {
        let result = {};
        res.forEach((item, index) => {
          if (item.props !== undefined) {
            let memberId = Object.keys(item.props)[0];
            result[memberId] = item.props[memberId];
          }
        });
        setIsLoading(false);
        console.debug('Successfully Fetched user Data!');
        setMemberDataContainer(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // #################################################################

  useEffect(() => {
    setIsLoading(true);
    setMembers([]);
    getWTRSetting(bearer, token, tenantId)
      .then((resp) => {
        if (resp.items.length === 0) {
          cachedSettingsKey = 'noKey'; //DO NOT change the 'noKey'; backend uses this for validation when saving user choice
        } else {
          cachedSettingsKey = resp.items[0].key.name;
          currFormat = resp.items[0].properties.choice;
        }
      })
      .catch((err) => {
        console.error(err);
      });
    getUserDepartment(bearer, token, tenantId)
      .then((deptId) => {
        if (deptId === mainSelectedDepartment) {
          if (!isLoading) setIsLoading(true);
          getDepartmentMembers(bearer, token, tenantId, mainSelectedDepartment, dtrDateTime)
            .then((newMembers) => {
              newMembers.items = newMembers.items.filter((item) => item[2] === 'true');
              setMembers(newMembers.items.map((item) => createData(item)));
              setMemberDataContainer({});
              console.debug('Fetching user data');
              fetchUserData(newMembers.items.map((item) => createData(item)));
            })
            .catch((err) => {
              console.error(err);
            });
          //Fetching DTR of a specific departemenet
          fetchDTR(bearer, token, tenantId, mainSelectedDepartment)
            .then((resp) => {
              setDepDtrArr(resp.items);
              setIsLoading(false);
              verifyDTRprevDaySubmision(resp.items);
            })
            .catch((err) => {
              console.error(err);
            });

          sessionStorage.setItem('dtrSentTime', null);
          fetchDailyTimeReportNote(bearer, token, tenantId, dtrDateTime, mainSelectedDepartment)
            .then((resp) => {
              setDepartmentNotes((resp && resp.responseMessage) || '');
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          setMainSelectedDepartment(deptId);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    getDepartmentForUI(bearer, token, tenantId)
      .then((depts) => {
        if (depts !== departments) {
          setDepartments(depts);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [tenantId]);

  useEffect(() => {
    if (mainSelectedDepartment !== null) {
      setMembers([]);
      if (!isLoading) setIsLoading(true);
      getDepartmentMembers(bearer, token, tenantId, mainSelectedDepartment, new Date())
        .then((newMembers) => {
          newMembers.items = newMembers.items.filter((item) => item[2] === 'true');
          setMembers(newMembers.items.map((item) => createData(item)));
          setMemberDataContainer({});
          console.debug('Fetching user data');
          fetchUserData(newMembers.items.map((item) => createData(item)));
        })
        .catch((err) => {
          console.error(err);
        });

      //Fetching DTR of a specific departemenet
      fetchDTR(bearer, token, tenantId, mainSelectedDepartment)
        .then((resp) => {
          setDepDtrArr(resp.items);
          setIsLoading(false);
          verifyDTRprevDaySubmision(resp.items);
        })
        .catch((err) => {
          console.error(err);
        });

      sessionStorage.setItem('dtrSentTime', null);

      fetchDailyTimeReportNote(bearer, token, tenantId, dtrDateTime, mainSelectedDepartment)
        .then((resp) => {
          setDepartmentNotes((resp && resp.responseMessage) || '');
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [mainSelectedDepartment]);

  useEffect(() => {
    if (members.length > 0 && tenantId !== null) {
      setMemberDataContainer({});
      setIsLoading(true);
      console.debug('Fetching user data for date change');
      fetchUserData(members);
    }
  }, [dtrDateTime]);

  const handleSaveDepartmentNote = async (event) => {
    event.preventDefault();
    updateDailyTimeReportNote(bearer, token, tenantId, dtrDateTime, mainSelectedDepartment, departmentNotes)
      .then((resp) => {
        resp.responseCode !== '0'
          ? console.error('Could not update daily time report notes')
          : console.debug('Successfully updated DTR department notes');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!showIconLegend) {
      members.forEach((member) => {
        if (memberDataContainer.hasOwnProperty(member.id)) {
          Object.values(memberDataContainer[member.id]).forEach((dataItem, index) => {
            if (Object.keys(memberDataContainer[member.id])[index] !== 'call_type') {
              if (ValueAndEmptyStringValidator(dataItem)) {
                setShowIconLegend(true);
              }
            }
          });
        }
      });
    }
  }, [memberDataContainer]);

  const fillEmptyBoxes = () => {
    const MEMBERS_NEEDED = 9;

    if (members.length < MEMBERS_NEEDED) {
      const boxesToFill = MEMBERS_NEEDED - members.length;

      return Array.from(Array(boxesToFill).keys()).map((item, index) => (
        <Box
          sx={{
            display: `${width > 500 ? 'flex' : 'inline-flex'}`,
            backgroundColor: `${
              members.length % 2 === 0
                ? index % 2 !== 0
                  ? '#DDDDDD42'
                  : '#F9F6F6'
                : index % 2 === 0
                ? '#DDDDDD42'
                : '#F9F6F6'
            }`,
          }}
        >
          <Box
            sx={{
              width: `${width > 500 ? '40%' : '130px'}`,
              borderRight: '1px solid #6C6D6B',
              borderBottom: '1px solid #D9D9D9',
            }}
          >
            <Typography sx={{ fontSize: '13px', paddingLeft: '20px', paddingTop: '5px', paddingBottom: '5px' }}>
              -
            </Typography>
          </Box>
          {Object.values(IconContainerState).map((item, index) =>
            item.use ? (
              <Box
                sx={{
                  width: `${width > 500 ? '15%' : '45px'}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRight: '1px solid #6C6D6B',
                  borderBottom: '1px solid #D9D9D9',
                }}
              />
            ) : null
          )}
        </Box>
      ));
    }
  };

  const markMissingMembers = (missingMembersArr) => {
    const newMemberDataContainerState = { ...memberDataContainer };

    missingMembersArr.forEach((missingMember) => {
      let tempContent = { ...saveContentFormat };
      let copyContent = {};

      if (memberDataContainer.hasOwnProperty(missingMember)) {
        copyContent = memberDataContainer[missingMember];
        for (const [key, value] of Object.entries(copyContent)) {
          tempContent[key] = value.value;
        }
      }

      tempContent.call_type = 'not-in';
      if (tempContent.kit_time === '-1') tempContent.kit_time = '-';
      if (tempContent.upgrade_time === '-1') tempContent.upgrade_time = '-';

      let storageContent = {};
      Object.keys(tempContent).forEach((item) => {
        storageContent[item] = { value: tempContent[item] };
      });

      let memberId = missingMember;

      newMemberDataContainerState[memberId] = storageContent;

      saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, memberId, tempContent)
        .then((resp) => {
          resp.responseCode === '0'
            ? console.debug('Successfully saved user data')
            : console.error('Could not save user data');
        })
        .catch((err) => {
          console.error(err);
        });
    });

    setMemberDataContainer(newMemberDataContainerState);
  };

  useEffect(() => {
    const newIconContainerState = { ...IconContainerState };

    newIconContainerState.travel_start_time.use = false;
    newIconContainerState.travel_end_time.use = false;
    newIconContainerState.second_meal_start_time.use = false;
    newIconContainerState.second_meal_end_time.use = false;
    newIconContainerState.kit_time.use = false;
    newIconContainerState.upgrade_time.use = false;

    for (const [key, value] of Object.entries(IconContainerState)) {
      if (!value.use) {
        for (const [id, call_type] of Object.entries(memberDataContainer)) {
          if (call_type.hasOwnProperty(key) && call_type[key].hasOwnProperty('value')) {
            if (call_type[key].value !== '-1' && call_type[key].value !== '-' && call_type[key].value !== '') {
              if (key === 'travel_start_time' || key === 'travel_end_time') {
                newIconContainerState.travel_start_time.use = true;
                newIconContainerState.travel_end_time.use = true;
              } else if (key === 'second_meal_start_time' || key === 'second_meal_end_time') {
                newIconContainerState.second_meal_start_time.use = true;
                newIconContainerState.second_meal_end_time.use = true;
              } else {
                newIconContainerState[key].use = true;
              }
              break;
            }
          }
        }
      }
    }

    setIconContainerState(newIconContainerState);
  }, [memberDataContainer]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <MainContainer
          setNewDTR={setNewDTR}
          members={members}
          community={community}
          onLogout={onLogout}
          departments={departments}
          mainSelectedDepartment={mainSelectedDepartment}
          setMainSelectedDepartment={setMainSelectedDepartment}
          setIsLoading={setIsLoading}
          setDtrDateTime={setDtrDateTime}
          dtrDateTime={dtrDateTime}
          setShowMemberView={setShowMemberView}
          setShowTableView={setShowTableView}
          showMemberView={showMemberView}
          showTableView={showTableView}
          backBtn={backBtn}
          bearer={bearer}
          token={token}
          tenantId={tenantId}
          communityMap={communityMap}
          setTenantId={setTenantId}
          setCommunity={setCommunity}
          setMemberDataContainer={setMemberDataContainer}
          displayToast={displayToast}
          setShowIconLegend={setShowIconLegend}
        />
        <Box pt={1} pl={2.5} pr={2.5}>
          <AddCrewMembers
            members={members}
            setMembers={setMembers}
            bearer={bearer}
            token={token}
            createData={createData}
            tenantId={tenantId}
            displayToast={displayToast}
            mainSelectedDepartment={mainSelectedDepartment}
          />
        </Box>
        {showMemberView ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ margin: '10px 25px', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: `${showIconLegend ? 'block' : 'none'}` }}>
                <IconLegend IconContainer={IconContainer} />
              </Box>
              <Box
                mt={3}
                pb={2}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '15px', flex: 1, overflowY: 'scroll' }}
              >
                {members.length > 0 ? (
                  members.map((item, keyNum) => (
                    <MemberDataRow
                      setIsLoading={setIsLoading}
                      item={item}
                      applyToAll={applyToAll}
                      applyToNext={applyToNext}
                      key={keyNum}
                      itemID={keyNum}
                      members={members}
                      bearer={bearer}
                      token={token}
                      tenantId={tenantId}
                      dtrDateTime={dtrDateTime}
                      memberDataContainer={memberDataContainer}
                      showTableView={showTableView}
                      displayToast={displayToast}
                      saveContentFormat={saveContentFormat}
                      setMemberDataContainer={setMemberDataContainer}
                    />
                  ))
                ) : (
                  <Typography pt={4} sx={{ textAlign: 'center' }}>
                    NO DATA AVAILABLE
                  </Typography>
                )}
                {isLoading ? <LoadingSpinner /> : ''}
              </Box>
            </Box>
          </Box>
        ) : showTableView ? (
          <Box sx={{ display: 'flex', margin: '10px 25px', flexDirection: 'column', justifyContent: 'space-between' }}>
            <IconLegend />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                sx={{
                  marginBottom: '26px',
                  marginTop: '26px',
                  overflowX: `${width > 500 ? 'visible' : 'scroll'}`,
                  overflowY: 'scroll',
                  backgroundColor: '#F9F6F6',
                  height: '300px',
                  borderRadius: '6px',
                  width: '100%',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                }}
              >
                <Box sx={{ display: `${width > 500 ? 'flex' : 'inline-flex'}`, backgroundColor: '#DDDDDD42' }}>
                  <Box
                    sx={{
                      width: `${width > 500 ? '40%' : '130px'}`,
                      borderRight: '1px solid #6C6D6B',
                      borderBottom: '1px solid #D9D9D9',
                      padding: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '15px',
                        paddingLeft: '20px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        fontFamily: 'Inter-Bold',
                      }}
                    >
                      Member
                    </Typography>
                  </Box>
                  {Object.values(IconContainerState).map((item) =>
                    item.use ? (
                      <Box
                        sx={{
                          width: `${width > 500 ? '15%' : '45px'}`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRight: '1px solid #6C6D6B',
                          borderBottom: '1px solid #D9D9D9',
                        }}
                      >
                        {item.component}
                      </Box>
                    ) : null
                  )}
                </Box>
                {members.length > 0 &&
                  members.map((item, keyNum) => (
                    <TableDataRow
                      width={width}
                      itemIndex={keyNum}
                      item={item}
                      showTableView={showTableView}
                      setIsLoading={setIsLoading}
                      applyToAll={applyToAll}
                      applyToNext={applyToNext}
                      key={keyNum}
                      itemID={keyNum}
                      keyNum={keyNum}
                      members={members}
                      bearer={bearer}
                      token={token}
                      tenantId={tenantId}
                      dtrDateTime={dtrDateTime}
                      displayToast={displayToast}
                      memberDataContainer={memberDataContainer}
                      setMemberDataContainer={setMemberDataContainer}
                      saveContentFormat={saveContentFormat}
                      IconContainerState={IconContainerState}
                    />
                  ))}
                {fillEmptyBoxes()}
                {isLoading ? <LoadingSpinner /> : ''}
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <OrderMembers
              mainSelectedDepartment={mainSelectedDepartment}
              members={members}
              setMembers={setMembers}
              bearer={bearer}
              token={token}
              setIsLoading={setIsLoading}
              dtrDateTime={dtrDateTime}
              tenantId={tenantId}
              memberDataContainer={memberDataContainer}
              setMemberDataContainer={setMemberDataContainer}
              saveContentFormat={saveContentFormat}
              createData={createData}
            />
            {isLoading ? <LoadingSpinner /> : ''}
          </>
        )}
      </Box>
      <Box pb={1.5} sx={{ margin: '10px 25px', display: 'flex', flexDirection: 'column' }}>
        {showTableView || showMemberView ? (
          <>
            <Typography sx={{ fontSize: '10px' }} pb={1}>
              Department Notes
            </Typography>
            <TextField
              sx={{ marginBottom: '20px' }}
              value={departmentNotes}
              onChange={(e) => setDepartmentNotes(e.target.value)}
              onBlur={handleSaveDepartmentNote}
            />
          </>
        ) : (
          <></>
        )}
        <SubmitButton
          cachedSettingsKey={cachedSettingsKey}
          cachedSetting={currFormat}
          depDtrArr={depDtrArr}
          bearer={bearer}
          setIsLoading={setIsLoading}
          token={token}
          tenantId={tenantId}
          members={members}
          markMissingMembers={markMissingMembers}
          memberDataContainer={memberDataContainer}
          mainSelectedDepartment={mainSelectedDepartment}
          dtrDateTime={dtrDateTime}
        />
      </Box>
    </Box>
  );
};

export default DailyTimeReportV2;
