import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Modal from 'react-modal';
import { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/system';
import { changePassword } from '../../services/dtrService';
import { logCustomEventSingleAction } from '../../../analytics';

export default function MenuListComposition({
  departments,
  selectedDepartment,
  setSelectedDepartment,
  setIsLoading,
  bearer,
  token,
  tenantId,
  communityMap,
  community,
  setTenantId,
  setCommunity,
  displayToast,
  setShowMemberView,
  setShowTableView,
  handleLogout,
  setNewDTR,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [changeDepartmentModal, setChangeDepartmentModal] = useState(false);
  const [changeProductionModal, setChangeProductionModal] = useState(false);

  const [tempSelectedDepartment, setTempSelectedDepartment] = useState(selectedDepartment);
  const [tempSelectedProduction, setTempSelectedProduction] = useState(null);

  const [oldPassword, setOldPassword] = useState('');
  const [newPasswordOne, setNewPasswordOne] = useState('');
  const [newPasswordTwo, setNewPasswordTwo] = useState('');

  useEffect(() => {
    Object.values(communityMap).forEach((item, index) => {
      if (community.name !== undefined) {
        if (item === community.name) {
          setTempSelectedProduction(Object.keys(communityMap)[index]);
        }
      } else {
        if (item === community) {
          setTempSelectedProduction(Object.keys(communityMap)[index]);
        }
      }
    });
  }, []);

  useEffect(() => {
    setTempSelectedDepartment(selectedDepartment);
  }, [selectedDepartment]);

  const resetPasswordModalStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '230px',
      height: '370px',
    },
    overlay: {
      zIndex: 1000,
      background: 'rgba(0,0,0,0.5)',
    },
  };

  const handleLogoutModified = (event) => {
    handleClose(event);
    handleLogout(event);
  };

  const changeDepartmentModalStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '230px',
      maxHeight: '250px',
    },
    overlay: {
      zIndex: 1000,
      background: 'rgba(0,0,0,0.5)',
    },
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleResetPasswordModal = (event) => {
    handleClose(event);
    setResetPasswordModal(true);
  };

  // Unused right now but we will be using product tours in the future
  // const handleProductTourModal = (event) => {
  //   handleClose(event);
  // };

  const handleSupportModal = (event) => {
    handleClose(event);
    window.open(
      'https://support.croogloo.com/en/collections/3494336-daily-time-reports',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const handleChangeProductionModal = (event) => {
    handleClose(event);
    setChangeProductionModal(true);
  };

  const handleChangeDepartmentModal = (event) => {
    handleClose(event);
    setChangeDepartmentModal(true);
  };

  const handleOrderMembersTab = (event) => {
    handleClose(event);
    setShowMemberView(false);
    setShowTableView(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleChangeDepartment = async (event) => {
    setTempSelectedDepartment(event.target.value);
  };

  const handleChangeProduction = async (event) => {
    setTempSelectedProduction(event.target.value);
  };

  const oldPasswordRef = useRef(null);
  const newPasswordOneRef = useRef(null);
  const newPasswordTwoRef = useRef(null);

  const handlePasswordChange = () => {
    if (newPasswordOne !== newPasswordTwo) {
      displayToast('New Passwords do not match', true);
      return;
    }

    if (newPasswordOne === '') {
      displayToast('New Password length must be greater than one', true);
      return;
    }
    setIsLoading(true);
    changePassword(bearer, token, tenantId, oldPassword, newPasswordOne)
      .then((resp) => {
        setIsLoading(false);
        if (resp.items[0] === 'password successfully changed') {
          displayToast('Successfully Changed Password', false);
          setResetPasswordModal(false);
        } else if (resp.items[0] === 'wrong password error') {
          displayToast('Old password entered was incorrect', true);
        } else {
          displayToast('Service not available at this time, please try again later', true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showPasswords = (e) => {
    if (e.target.checked) {
      oldPasswordRef.current.type = 'text';
      newPasswordOneRef.current.type = 'text';
      newPasswordTwoRef.current.type = 'text';
    } else {
      oldPasswordRef.current.type = 'password';
      newPasswordOneRef.current.type = 'password';
      newPasswordTwoRef.current.type = 'password';
    }
  };

  const handleProductionSubmission = () => {
    let desiredIndex;

    Object.keys(communityMap).forEach((item, index) => {
      if (item === tempSelectedProduction) {
        desiredIndex = index;
        setTenantId(item);
        setCommunity(Object.values(communityMap)[desiredIndex]);
      }
    });
  };

  return (
    <Stack sx={{ zIndex: 100 }} direction="row" spacing={2}>
      <div>
        <IconButton ref={anchorRef} onClick={handleToggle} sx={{ padding: '4px 8px 0 8px' }}>
          <MenuIcon
            style={{ color: 'white', fontSize: '30px' }}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
          />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem sx={{ fontFamily: 'Inter-Bold' }} onClick={handleOrderMembersTab}>
                      Order Members
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Inter-Bold' }} onClick={handleChangeDepartmentModal}>
                      Change Department
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Inter-Bold' }} onClick={handleSupportModal}>
                      Support
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Inter-Bold' }} onClick={handleChangeProductionModal}>
                      Switch Production
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: 'Inter-Bold' }} onClick={handleResetPasswordModal}>
                      Reset Password
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: 'Inter-Bold' }}
                      onClick={() => {
                        logCustomEventSingleAction('Menu', 'Switched to Old DTR Design');
                        setNewDTR(false);
                      }}
                    >
                      Use Old Design
                    </MenuItem>
                    {/* <MenuItem onClick={handleProductTourModal}>Product Tour</MenuItem> */}
                    <MenuItem sx={{ fontFamily: 'Inter-Bold' }} onClick={handleLogoutModified}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={resetPasswordModal}
        onRequestClose={() => setResetPasswordModal(false)}
        style={resetPasswordModalStyles}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={() => setResetPasswordModal(false)} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>RESET PASSWORD</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '7px' }}>
              <label style={{ fontSize: '12px', fontFamily: 'Inter' }}>Type in your old password</label>
              <input
                ref={oldPasswordRef}
                onChange={(e) => setOldPassword(e.target.value)}
                style={{
                  height: '30px',
                  width: '100%',
                  border: '1px solid gray',
                  borderRadius: '6px',
                  fontFamily: 'Inter',
                }}
                type="password"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '7px' }}>
              <label style={{ fontSize: '12px', fontFamily: 'Inter' }}>Type in your new password</label>
              <input
                ref={newPasswordOneRef}
                onChange={(e) => setNewPasswordOne(e.target.value)}
                style={{
                  height: '30px',
                  width: '100%',
                  border: '1px solid gray',
                  borderRadius: '6px',
                  fontFamily: 'Inter',
                }}
                type="password"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '7px' }}>
              <label style={{ fontSize: '12px', fontFamily: 'Inter' }}>Type in your new password again</label>
              <input
                ref={newPasswordTwoRef}
                onChange={(e) => setNewPasswordTwo(e.target.value)}
                style={{
                  height: '30px',
                  width: '100%',
                  border: '1px solid gray',
                  borderRadius: '6px',
                  fontFamily: 'Inter',
                }}
                type="password"
              />
            </div>
          </div>
          <div style={{ display: 'flex', columnGap: '4px', fontSize: '12px', fontFamily: 'Inter' }}>
            <input type="checkbox" onChange={(e) => showPasswords(e)} />
            <label>Show passwords?</label>
          </div>
          <Button
            variant="contained"
            sx={{ color: 'white', backgroundColor: '#01AA25', width: '100%' }}
            onClick={handlePasswordChange}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Modal
        isOpen={changeDepartmentModal}
        style={changeDepartmentModalStyles}
        ariaHideApp={false}
        onRequestClose={() => setChangeDepartmentModal(false)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={() => setChangeDepartmentModal(false)} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>CHANGE DEPARTMENT</Typography>
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
              {departments?.items.length > 0 && (
                <TextField
                  size="small"
                  label="Departments"
                  select
                  sx={{ my: '8px', width: '100%' }}
                  value={tempSelectedDepartment}
                  onChange={(event) => handleChangeDepartment(event)}
                >
                  {departments.items.map((item, index) => (
                    <MenuItem value={item.properties.id} key={index}>
                      {item.properties.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </div>
          </div>
          <Button
            onClick={() => {
              if (selectedDepartment !== tempSelectedDepartment) setSelectedDepartment(tempSelectedDepartment);
              setChangeDepartmentModal(false);
            }}
            variant="contained"
            sx={{ color: 'white', backgroundColor: '#01AA25', width: '100%' }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Modal
        isOpen={changeProductionModal}
        onRequestClose={() => setChangeProductionModal(false)}
        style={changeDepartmentModalStyles}
        ariaHideApp={false}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '25px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={() => setChangeProductionModal(false)} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>SWITCH PRODUCTION</Typography>
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
              {Object.values(communityMap)?.length > 0 && (
                <TextField
                  size="small"
                  label="Productions"
                  select
                  sx={{ my: '8px', width: '100%' }}
                  value={tempSelectedProduction}
                  onChange={(event) => handleChangeProduction(event)}
                >
                  {Object.values(communityMap).map((item, index) => (
                    <MenuItem value={Object.keys(communityMap)[index]} key={index}>
                      {item.toUpperCase()}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </div>
          </div>
          <Button
            onClick={() => {
              setChangeProductionModal(false);
              handleProductionSubmission();
            }}
            variant="contained"
            sx={{ color: 'white', backgroundColor: '#01AA25', width: '100%' }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
}
