import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './customDialog.css';

const CustomDialog = (props) => {
  const handleConfirm = () => {
    if (props.confirmCallback) {
      props.confirmCallback();
    } else {
      props.onClose();
    }
  };

  const handelCancel = () => {
    props.onClose();
    props.cancelCallback && props.cancelCallback();
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth={props.fullWidth || false}
      maxWidth={props.maxWidth || 'sm'}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{props.contentText}</DialogContentText>
        {props.body}
      </DialogContent>
      <DialogActions>
        <Button
          {...props.cancelButtonProps}
          {...(!props.cancelButtonProps?.color ? { color: 'error' } : {})}
          variant="contained"
          className="cancelButton"
          onClick={handelCancel}
        >
          {props.cancelButtonMessage || 'Cancel'}
        </Button>
        <Button
          {...props.confirmButtonProps}
          {...(!props.confirmButtonProps?.color ? { color: 'success' } : {})}
          variant="contained"
          autoFocus
          onClick={handleConfirm}
        >
          {props.confirmButtonMessage || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * You can set a dialog maximum width by using the maxWidth enumerable in combination with the fullWidth boolean.
 * When the fullWidth prop is true, the dialog will adapt based on the maxWidth value.
 *
 * maxWidth: [false | xs | sm | md | lg | xl]
 */
CustomDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
  contentText: PropTypes.string,
  body: PropTypes.element,
  confirmCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  confirmButtonMessage: PropTypes.string,
  confirmButtonProps: PropTypes.object,
  cancelButtonMessage: PropTypes.string,
  cancelButtonProps: PropTypes.object,
};

export default CustomDialog;
