import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import LoadingSpinner from '../loadingSpinner';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

import { getDailyTimeLogs, saveDailyTimeLogs } from '../../services/dtrService.js';
import TimeFormatCustom from './timeFormatCustom';
import './index.css';

// prettier-ignore
const columns = [
  { id: 'name', label: 'Name', type: 'text', optional: false, minWidth: 75, format: TimeFormatCustom },
  { id: 'call_type', label: 'Daily / Weekly', type: 'select', optional: false, minWidth: 100, format: TimeFormatCustom },
  { id: 'travel_start_time', label: 'Travel Start', type: 'input', optional: true, minWidth: 75, format: TimeFormatCustom },
  { id: 'call_time', label: 'Call Time', type: 'input', optional: false, minWidth: 75, format: TimeFormatCustom },
  { id: 'first_meal_start_time', label: '1st Meal - Start', type: 'input', optional: false, minWidth: 75, format: TimeFormatCustom },
  { id: 'first_meal_end_time', label: '1st Meal - Finish', type: 'input', optional: false, minWidth: 75, format: TimeFormatCustom },
  { id: 'second_meal_start_time', label: '2nd Meal - Start', type: 'input', optional: true, minWidth: 75, format: TimeFormatCustom },
  { id: 'second_meal_end_time', label: '2nd Meal - Finish', type: 'input', optional: true, minWidth: 75, format: TimeFormatCustom },  
  { id: 'wrap_time', label: 'Wrap Time', type: 'input', optional: false, minWidth: 75, format: TimeFormatCustom }, 
  { id: 'travel_end_time', label: 'Travel Finish', type: 'input', optional: true, minWidth: 75, format: TimeFormatCustom },
  { id: 'kit_time', label: 'Kit', type: 'input', optional: true, minWidth: 75 },
  { id: 'upgrade_time', label: 'Upgrade', type: 'input', optional: true, minWidth: 75 },  
  { id: 'other_notes', label: 'Other & Notes', type: 'input', optional: true, minWidth: 75 },  
  { id: 'actions', label: ' ', type: 'actions', optional: false, maxWidth: '110px', minWidth: 125 },

];

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}));

const CustomTextField = styled((props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #999',
      borderRadius: '6px',
      overflow: 'hidden',
      backgroundColor: '#fcfcfb',
    }}
  >
    <div
      style={{
        backgroundColor: '#40C365',
        color: 'white',
        fontWeight: 'bold',
        width: '50px',
        fontSize: '0.75em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '2px',
      }}
    >
      {props.label}
    </div>
    <TextField {...props} />
  </div>
))(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    '&.Mui-focused': {
      border: 'none',
    },
  },
}));

class DTRGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      showOptional: true,
      isTableView: false,
      isTableDataLoading: false,
      membersInput: {},
      dtrDateTime: null,
    };
    this.isDirty = false;
  }

  static propTypes = {
    bearer: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    members: PropTypes.array,
    isLoading: PropTypes.bool,
    dtrDateTime: PropTypes.number,
  };

  static defaultProps = {};

  componentDidUpdate(prevProps) {
    const { bearer, token, tenantId, dtrDateTime, members } = this.props;

    if (JSON.stringify(prevProps.members) !== JSON.stringify(members) || prevProps.dtrDateTime !== dtrDateTime) {
      const personId = members.map((member) => member.id).join(',');
      getDailyTimeLogs(bearer, token, tenantId, dtrDateTime, personId).then((resp) => this.updateDailyTimeLogs(resp));
    }

    if (prevProps.dtrDateTime !== dtrDateTime) {
      this.setState({
        membersInput: {},
        isTableDataLoading: true,
      });
    }
  }

  updateDailyTimeLogs = (logs) => {
    const newMembersInput = {};

    if (logs?.props) {
      Object.keys(logs.props).forEach((personId) => {
        newMembersInput[personId] = {};

        Object.keys(logs.props[personId]).forEach((type) => {
          newMembersInput[personId][type] = logs.props[personId][type].value;
        });

        if (!newMembersInput[personId].hasOwnProperty('call_type')) {
          newMembersInput[personId]['call_type'] = 'weekly';
        }
      });
    } else {
      this.props.members.forEach((member) => {
        newMembersInput[member.id] = {};
        let callTypeVal = localStorage.getItem(member.id + '_CALL_TYPE');

        //When user logs in with a new tab / window, the localStorage would be empty
        //Therfore we need to check if the value exist, if not use the default ('weekly') instead
        if (callTypeVal) {
          newMembersInput[member.id]['call_type'] = callTypeVal;
          this.saveDailyTimeLogs(member.id, { call_type: callTypeVal }); //Saving call type into database
        } else {
          newMembersInput[member.id]['call_type'] = 'weekly';
        }
      });
    }

    this.setState({ membersInput: newMembersInput, isTableDataLoading: false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleChangeDaily = (event, row) => {
    const newValue = {
      ...this.state.membersInput[row.id],
      call_type: event.target.value,
    };

    const membersInput = this.state.membersInput;
    membersInput[row.id] = newValue;

    this.setState({
      membersInput: {
        ...membersInput,
      },
    });

    this.saveDailyTimeLogs(row.id, { call_type: event.target.value }); //Saving logs in database

    localStorage.setItem(row.id + '_CALL_TYPE', event.target.value); //Saving call type localy
  };

  handleChangeInput = (event, row) => {
    const newValue = {
      ...this.state.membersInput[row.id],
    };

    newValue[event.target.name] = event.target.value;
    const membersInput = this.state.membersInput;
    membersInput[row.id] = newValue;

    this.isDirty = true;
    this.setState({
      membersInput: {
        ...membersInput,
      },
    });
  };

  toggleOptionalFields = () => {
    this.setState({ showOptional: !this.state.showOptional });
  };

  setTableView = () => {
    this.setState({ isTableView: true });
  };

  setGridView = () => {
    this.setState({ isTableView: false });
  };

  handleApplyToNext = (event, row) => {
    const members = this.props.members.filter((member) => member.isDisplay);
    const indexToCopy = members.findIndex((member) => member.id === row.id);

    if (indexToCopy !== -1) {
      const indexToApply = indexToCopy + 1;
      if (indexToApply < members.length) {
        const content = this.state.membersInput[row.id];
        const newMembersInput = {
          ...this.state.membersInput,
          [members[indexToApply].id]: content,
        };

        this.setState({
          membersInput: newMembersInput,
        });

        this.saveDailyTimeLogs(members[indexToApply].id, content);
      }
    }
  };

  handleApplyToAll = (event, row) => {
    const content = this.state.membersInput[row.id];
    const newMembersInput = {};
    const displayedMembers = this.props.members.filter((member) => member.isDisplay);
    displayedMembers.map((member) => (newMembersInput[member.id] = { ...content }));

    this.setState({ membersInput: newMembersInput });
    const personIds = displayedMembers.map((member) => member.id).join(',');
    this.saveDailyTimeLogs(personIds, content);
  };

  handleCopyContent = (event, row) => {
    event.preventDefault();

    this.setState({ copiedContent: this.state.membersInput[row.id] });
  };

  handlePasteContent = (event, row) => {
    event.preventDefault();

    const membersInput = this.state.membersInput;
    membersInput[row.id] = this.state.copiedContent;

    this.setState({
      membersInput: {
        ...membersInput,
      },
    });
  };

  handleSaveDaily = (event, row) => {
    //isDirty is true if the state of the input changed
    if (this.isDirty) {
      const type = [event.target.name];
      const value = this.state.membersInput[row.id][type];

      this.saveDailyTimeLogs(row.id, { [type]: value });
      this.isDirty = false;
    }
  };

  saveDailyTimeLogs(personIds, logs) {
    const { bearer, token, tenantId, dtrDateTime } = this.props;

    saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, personIds, logs);
  }

  createTableHeader = (columnsData) => {
    return (
      columnsData &&
      columnsData.map((column) => {
        const style = {};

        if (column.minWidth) {
          style.minWidth = column.minWidth;
          style.width = column.minWidth;
        }

        return (
          <TableCell
            align="center"
            className={column.id === 'name' ? 'thSticky th' : 'th'}
            style={style}
            key={column.id}
          >
            {column.label}
          </TableCell>
        );
      })
    );
  };

  createTableRows = (data, columnsData) => {
    const { page, rowsPerPage } = this.state;

    return (
      data &&
      data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
        return (
          <TableRow className="tr" hover tabIndex={-1} key={index}>
            {columnsData.map((column) => this.createCell(column, row))}
          </TableRow>
        );
      })
    );
  };

  handleEnter = (event) => {
    //Go to the next valid input field when "enter" is pressed
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      let i = 0;
      let elem;
      do {
        i++;
        elem = form.elements[index + i];
      } while (
        // Select is wrapped in a input of type 'text' by MUI so we make sure we are skipping this one aswell
        elem.type !== 'text' ||
        elem.name === 'call_type'
      );

      //Trigger the onBlur effect of the current target
      event.target.blur();
      elem.focus();
      event.preventDefault();
    }
  };

  createCell = (column, row) => {
    let elem = row.name;

    switch (column.type) {
      case 'text':
        elem = this.state.isTableView ? (
          row.name
        ) : (
          <Typography
            variant="subtitle1"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}
            component="div"
          >
            {row.name}
          </Typography>
        );
        break;
      case 'actions':
        elem = (
          <div className="actionsContainer">
            <Button
              variant="contained"
              fullWidth
              size="small"
              disabled={this.state.isTableDataLoading}
              onClick={(event) => this.handleApplyToNext(event, row)}
            >
              Apply to Next
            </Button>
            <Button
              variant="contained"
              fullWidth
              size="small"
              disabled={this.state.isTableDataLoading}
              onClick={(event) => this.handleApplyToAll(event, row)}
            >
              Apply to All
            </Button>
          </div>
        );
        break;
      case 'select':
        elem = (
          <Select
            name={column.id}
            autoWidth={true}
            className="tdSelect"
            value={(this.state.membersInput[row.id] && this.state.membersInput[row.id][column.id]) || 'weekly'}
            disabled={this.state.isTableDataLoading}
            onChange={(event) => this.handleChangeDaily(event, row)}
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="not-in">Not In</MenuItem>
          </Select>
        );
        break;
      case 'input':
        const inputValue = (this.state.membersInput[row.id] && this.state.membersInput[row.id][column.id]) || '';
        const isCallTypeDisabled = this.state.membersInput[row.id]?.call_type === 'not-in';

        let formatProps = {};
        if (column.format) {
          formatProps = {
            placeholder: '00:00',
            InputProps: {
              inputComponent: column.format,
            },
          };
        }

        elem = this.state.isTableView ? (
          <TextField
            name={column.id}
            label={column.label}
            variant="filled"
            size="small"
            InputLabelProps={{
              style: {
                fontSize: 12,
              },
            }}
            value={this.formatInputValue(inputValue)}
            disabled={this.state.isTableDataLoading || isCallTypeDisabled}
            onChange={(event) => this.handleChangeInput(event, row)}
            onKeyDown={this.handleEnter}
            onBlur={(event) => this.handleSaveDaily(event, row)}
            {...formatProps}
          />
        ) : (
          <CustomTextField
            name={column.id}
            fullWidth={true}
            label={column.label}
            InputLabelProps={{
              style: {
                display: 'none',
              },
            }}
            size="small"
            variant="outlined"
            style={{ padding: '4px' }}
            value={this.formatInputValue(inputValue)}
            disabled={this.state.isTableDataLoading || isCallTypeDisabled}
            onChange={(event) => this.handleChangeInput(event, row)}
            onKeyDown={this.handleEnter}
            onBlur={(event) => this.handleSaveDaily(event, row)}
            {...formatProps}
          />
        );
        break;
      default:
        return elem;
    }

    return (
      <TableCell className={column.id === 'name' ? 'tdSticky td' : 'td'} key={column.id}>
        {elem}
      </TableCell>
    );
  };

  formatInputValue = (value) => {
    // eslint-disable-next-line eqeqeq
    if (!value || value === -1) {
      return '';
    }

    return value;
  };

  render() {
    const { members, isLoading, inclusionCheck } = this.props;
    const { page, rowsPerPage, showOptional, isTableView, isTableDataLoading } = this.state;

    //Create a json object from the array and filter the members that should be excluded from the table
    let tempDtrTime = new Date(this.props.dtrDateTime);
    tempDtrTime.setHours(0, 0, 0, 0);

    const data = members.filter((member) => {
      if (inclusionCheck(member, tempDtrTime)) {
        return false;
      } else {
        return member.isDisplay;
      }
    });
    const columnsData = columns.filter((column) => (showOptional ? true : !column.optional));

    return (
      <React.Fragment>
        <Box sx={{ width: 300, mx: 'auto', display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
          <Box
            sx={{
              width: 300,
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="text"
              size="small"
              style={{ color: 'black', minWidth: '125px' }}
              onClick={this.setGridView}
              disabled={!isTableView}
              className={!isTableView ? 'viewButtonSelected' : ''}
            >
              Member View
            </Button>
            <Button
              variant="text"
              size="small"
              style={{ color: 'black', minWidth: '125px' }}
              onClick={this.setTableView}
              disabled={isTableView}
              className={isTableView ? 'viewButtonSelected' : ''}
            >
              Table View
            </Button>
          </Box>
          <FormGroup style={{ margin: 'auto' }}>
            <FormControlLabel
              control={
                <GreenSwitch
                  checked={!showOptional}
                  onChange={this.toggleOptionalFields}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={showOptional ? 'Hide optional fields' : 'Show optional fields'}
            />
          </FormGroup>
        </Box>

        <Paper
          sx={{ width: '100 % ', overflow: 'hidden', backgroundColor: 'transparent' }}
          className={!isTableView ? 'gridView' : ''}
        >
          <form>
            <TableContainer>
              <Table className="table" stickyHeader aria-label="sticky table">
                {(data.length === 0 || isLoading) && (
                  <caption className="tableCaption">{isLoading ? <CircularProgress /> : 'No data available'}</caption>
                )}
                <TableHead className="thead">
                  <TableRow className="tr">{this.createTableHeader(columnsData)}</TableRow>
                </TableHead>
                <TableBody className="tbody">{data.length > 0 && this.createTableRows(data, columnsData)}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </form>
        </Paper>
        {!isLoading && isTableDataLoading ? <LoadingSpinner /> : ''}
      </React.Fragment>
    );
  }
}

export { DTRGrid };
