import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingSpinner from '../../components/loadingSpinner';

import {
  fetchDTR,
  saveWTRSetting,
  getWTRSetting,
  getDepartmentForUI,
  getDepartmentMembers,
  getUserDepartment,
  updateDtrOrdering,
  updateDtrInclusions,
  addTimeReportPerson,
  submitDailyTimeReport,
  submitWeeklyTimeReport,
  fetchDailyTimeReportNote,
  updateDailyTimeReportNote,
  removeTimeReportPerson,
} from '../../services/dtrService.js';
import { logoutUser } from '../../services/logoutService.js';
import { DTRGrid } from '../../components/dtrGrid';
import DtrOrderMemberModal from '../../components/dtrOrderMemberModal';
import DtrMemberManagementModal from '../../components/dtrMemberManagementModal';
import DailySubmitDialog from '../../components/dailySubmitDialog';
import WeeklySubmitDialog from '../../components/weeklySubmitDialog';
import './index.css';
import Swal from 'sweetalert2';

var depDtrArr = []; // DTRs of a specific department

var cachedSettingsKey = ''; //Key of the setting choice of the user for the WTR
var currFormat = ''; //Current CASHED format for WTR

let today = new Date(); //Beginning of today's date, used for overnight shoot swal
today.setHours(0, 0, 0, 0);

let prevDate = new Date(); //Beginning of yesterday's date, used for overnight shoot swal
prevDate.setDate(today.getDate() - 1);
prevDate.setHours(0, 0, 0, 0);

class DailyTimeReport extends React.Component {
  constructor(props) {
    super(props);
    this.dtrGridRef = React.createRef();
    this.state = {
      selectedDepartment: '',
      departmentNotes: '',
      dtrDateTime: new Date().getTime(),
      departments: null,
      members: [],
      isLoading: false,
      isLoginOut: false,
      showMemberManagementModal: false,
      showOrderMemberModal: false,
      showDailySubmitDialog: false,
      showWeeklySubmitDialog: false,
      submitOpen: false,
      submitAnchorEl: null,
      datePickerOpen: false,
    };
  }

  static propTypes = {
    bearer: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    community: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    const { bearer, token, tenantId } = this.props;
    document.title = 'DTR';

    getWTRSetting(bearer, token, tenantId).then((resp) => {
      if (resp.items.length === 0) {
        cachedSettingsKey = 'noKey'; //DO NOT change the 'noKey'; backend uses this for validation when saving user choice
      } else {
        cachedSettingsKey = resp.items[0].key.name;
        currFormat = resp.items[0].properties.choice;
      }
    });

    getUserDepartment(bearer, token, tenantId)
      .then((departmentId) => {
        this.setState({
          selectedDepartment: departmentId,
          isLoading: true,
        });

        return departmentId;
      })
      .then((departmentId) => {
        getDepartmentMembers(bearer, token, tenantId, departmentId, new Date()).then((members) => {
          let showDtrSwitchSwal = localStorage.getItem('showDtrSwitchSwal');
          localStorage.clear();
          if (showDtrSwitchSwal) localStorage.setItem('showDtrSwitchSwal', true);
          this.handleFetchedMembers(members, bearer, token, tenantId);
        });

        //Fetching DTR of a specific departemenet
        fetchDTR(bearer, token, tenantId, departmentId).then((resp) => {
          depDtrArr = resp.items;
          this.verifyDTRprevDaySubmision(depDtrArr);
        });
        sessionStorage.setItem('dtrSentTime', null);

        fetchDailyTimeReportNote(bearer, token, tenantId, this.state.dtrDateTime, departmentId).then((resp) =>
          this.setState({
            departmentNotes: (resp && resp.responseMessage) || '',
          })
        );
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          members: [],
        });
      });

    getDepartmentForUI(bearer, token, tenantId).then((departments) => this.setState({ departments }));
  }

  handleChangeDepartment = async (event) => {
    const { bearer, token, tenantId } = this.props;

    const departmentId = event.target.value;
    getDepartmentMembers(bearer, token, tenantId, departmentId, this.state.dtrDateTime)
      .then((members) => {
        let showDtrSwitchSwal = localStorage.getItem('showDtrSwitchSwal');
        localStorage.clear();
        if (showDtrSwitchSwal) localStorage.setItem('showDtrSwitchSwal', true);
        this.handleFetchedMembers(members, bearer, token, tenantId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          members: [],
        });
      });

    //Fetching DTR of a specific departemenet on departemenet change
    fetchDTR(bearer, token, tenantId, departmentId).then((resp) => {
      depDtrArr = resp.items;
      this.verifyDTRprevDaySubmision(depDtrArr);
    });
    sessionStorage.setItem('dtrSentTime', null);

    this.setState({
      selectedDepartment: departmentId,
      isLoading: true,
      members: [],
    });
  };

  //Method verifies if the currently selected departement has DTRs submitted for the previous day, in case of overnight shoots
  verifyDTRprevDaySubmision = (dtrArray) => {
    let today = new Date(this.state.dtrDateTime);
    today.setHours(0, 0, 0, 0);

    let prevDate = new Date(this.state.dtrDateTime);
    prevDate.setDate(today.getDate() - 1);
    prevDate.setHours(0, 0, 0, 0);

    let tempArr = dtrArray.filter((dtr) => {
      return (
        dtr.properties.target_report_time >= prevDate.getTime() && dtr.properties.target_report_time < today.getTime()
      );
    });

    if (tempArr.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Missing DTR',
        html:
          'No DTRs were found for <b>' +
          prevDate.toDateString() +
          '</b>. Would you like to submit the hours for that day?',
        confirmButtonText: 'Yes, change the date',
        confirmButtonColor: '#13C46A',
        cancelButtonText: 'No, leave this date',
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.backBtn.click();
        }
      });
    }
  };

  onAddPerson = async (firstName, lastName, jobTitle) => {
    const { bearer, token, tenantId } = this.props;

    const resp = await addTimeReportPerson(
      bearer,
      token,
      tenantId,
      this.state.selectedDepartment,
      firstName,
      lastName,
      jobTitle
    );

    const respError = resp.responseCode !== '0';
    const msg = respError ? 'Service unavailable try again later' : 'Department Member Added!';
    this.displayToast(msg, respError);

    getDepartmentMembers(bearer, token, tenantId, this.state.selectedDepartment, new Date()).then((members) =>
      this.setState({
        members: (members && members.items.map((item) => this.createData(item))) || [],
      })
    );
  };

  handleFetchedMembers = (members, bearer, token, tenantId) => {
    let membersMapped = members.items.map((item) => this.createData(item));
    let tempDtrTime = new Date(this.state.dtrDateTime);

    //Deleted members should be compared against user's machine date
    //otherwse if user accidentaly change date of dtr on app, it can wipe deleted members.
    let now = new Date().setHours(0, 0, 0, 0);

    let membersIDtoDelete = '';
    let membersToDisplay = [];

    for (let ent of membersMapped) {
      if (ent.dateToPermanentlyRemove && ent.dateToPermanentlyRemove <= now) {
        membersIDtoDelete += ent.id + ' , ';
      } else {
        if (ent.deletedDate && parseInt(ent.deletedDate) > tempDtrTime.getTime()) {
          ent.isDisplay = true;
        }
        membersToDisplay.push(ent);
      }
    }

    if (membersIDtoDelete.length > 0) {
      removeTimeReportPerson(bearer, token, tenantId, membersIDtoDelete).then(() => {
        this.setState({
          members: (members && membersToDisplay) || [],
          isLoading: false,
        });
      });
    } else {
      this.setState({
        members: (members && membersToDisplay) || [],
        isLoading: false,
      });
    }
  };

  //Parse the members Array into an object
  createData = (row) => {
    if (row[6] && row[7]) {
      return {
        id: row[0],
        name: row[1],
        isDisplay: row[2] === 'true',
        firstname: row[3],
        lastname: row[4],
        department: row[5],
        deletedDate: row[6],
        dateToPermanentlyRemove: row[7],
      };
    } else {
      return {
        id: row[0],
        name: row[1],
        isDisplay: row[2] === 'true',
        firstname: row[3],
        lastname: row[4],
        department: row[5],
      };
    }
  };

  displayToast = (message, isError) => {
    const config = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (isError) {
      toast.error(message, config);
    } else {
      toast.success(message, config);
    }
  };

  setShowMemberManagementModal = (isShown) => {
    this.setState({ showMemberManagementModal: isShown });
  };

  setShowOrderMemberModal = (isShown) => {
    this.setState({ showOrderMemberModal: isShown });
  };

  handleShowWeeklySubmitDialog = (isShown) => {
    this.setState({ showWeeklySubmitDialog: isShown });
  };

  handleShowDailySubmitDialog = (isShown) => {
    this.setState({ showDailySubmitDialog: isShown });
  };

  handleChangeDate = (event, delta) => {
    event.preventDefault();

    const dt = 1000 * 60 * 60 * 24 * delta;

    this.setState({
      dtrDateTime: new Date(this.state.dtrDateTime + dt).getTime(),
    });

    this.handleDtrInclusion(this.state.members, new Date(this.state.dtrDateTime + dt));
  };

  handleDatePickerDateChange = (date) => {
    this.setState({
      dtrDateTime: new Date(date).getTime(),
    });

    this.handleDtrInclusion(this.state.members, new Date(date));
  };

  handleSubmitDaily = async () => {
    await this.handleDtrInclusion(this.state.members, undefined, 'dtr');

    const { bearer, token, tenantId } = this.props;
    const resp = await submitDailyTimeReport(
      bearer,
      token,
      tenantId,
      this.state.selectedDepartment,
      this.state.dtrDateTime
    );

    const respError = resp.responseCode !== '0';

    if (respError) {
      Swal.fire({
        icon: 'error',
        text: 'Service unavailable try again later',
        confirmButtonColor: '#13C46A',
        showCloseButton: true,
        showCancelButton: false,
      });
    } else {
      Swal.fire({
        icon: 'success',
        text: 'Daily Time Report Submitted!',
        confirmButtonColor: '#13C46A',
        showCloseButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
      });
      sessionStorage.setItem('dtrSentTime', this.state.dtrDateTime); //Setting flag so that user can imediatly send out WTR without having to refresh page for DB to return a valid DTR
    }
  };
  handleSubmitWeekly = async (startOfWeek, format) => {
    const { bearer, token, tenantId } = this.props;

    var tempStart = new Date(startOfWeek).getTime();
    var tempEnd = new Date(startOfWeek);

    tempEnd.setDate(tempEnd.getDate() + 7);
    tempEnd = tempEnd.getTime();

    var cont = false;

    var sesionStorageTime = sessionStorage.getItem('dtrSentTime');

    //Checking if there is atleast one DTRs of a departement that has been sent in the week where the user wants to generate a weekly.
    if (depDtrArr.length > 0) {
      for (let i of depDtrArr) {
        if (i.properties.submitted_time >= tempStart && i.properties.submitted_time < tempEnd) {
          cont = true;
          break;
        }
      }
    }

    //Checking session storage after checking detDtrArr to make sure we can send out DTR
    //We do this steb b/c it is possible for depDtrArr.length > 0 withotu counting the one in session storage
    if (!cont && sesionStorageTime !== null && sesionStorageTime >= tempStart && sesionStorageTime < tempEnd) {
      cont = true;
    }

    if (cont) {
      const resp = await submitWeeklyTimeReport(
        bearer,
        token,
        tenantId,
        this.state.selectedDepartment,
        new Date(startOfWeek).getTime(),
        format
      );

      const respError = resp.responseCode !== '0';

      if (respError) {
        Swal.fire({
          icon: 'error',
          text: 'Service unavailable try again later',
          confirmButtonColor: '#13C46A',
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        Swal.fire({
          icon: 'success',
          text: 'Weekly Time Report Submitted!',
          confirmButtonColor: '#13C46A',
          showCloseButton: true,
          showCancelButton: false,
          allowOutsideClick: true,
        });
      }
    } else {
      //Swal is fired if no DTR exist in a week where the user wants a WTR
      Swal.fire({
        icon: 'info',
        title: 'No Daily Time Reports Found',
        text: 'Please submit at least one Daily before generating a Weekly.',
        showCancelButton: false,
        showConfirmButton: true,
        showCloseButton: true,
        allowOutsideClick: true,
        confirmButtonText: 'Got it',
        confirmButtonColor: '#13c46a',
      });
    }

    if (currFormat !== format) {
      //Saving setings only if fomat has changed

      saveWTRSetting(bearer, token, tenantId, format, cachedSettingsKey);
    }
  };

  handleSubmitMenuClick = (event) => {
    if (this.state.submitAnchorEl === null) {
      this.setState({ submitAnchorEl: event.currentTarget, submitOpen: true });
    } else {
      this.setState({ submitAnchorEl: null, submitOpen: false });
    }
  };

  handleDatePickerOpen = (open) => {
    this.setState({ datePickerOpen: open });
  };

  handleLogout = async (event) => {
    event.preventDefault();
    const { token, bearer, onLogout } = this.props;
    this.setState({ isLoginOut: true });
    await logoutUser(token, bearer);
    onLogout();
  };

  formatDtrDate = () => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    }).format(new Date(this.state.dtrDateTime));
  };

  handleNotesChange = (event) => {
    event.preventDefault();
    this.setState({
      departmentNotes: event.target.value,
    });
  };

  handleSaveDepartmentNote = async (event) => {
    event.preventDefault();
    const { bearer, token, tenantId } = this.props;
    const { dtrDateTime, selectedDepartment, departmentNotes } = this.state;

    updateDailyTimeReportNote(bearer, token, tenantId, dtrDateTime, selectedDepartment, departmentNotes);
  };

  onManagementOptionsApply = (copy) => {
    this.setState({ members: copy });

    this.handleDtrInclusion(copy);
  };

  handleDtrInclusion = (copy, time = new Date(this.state.dtrDateTime), type = '') => {
    const { bearer, token, tenantId } = this.props;

    let tempDtrTime = time;
    tempDtrTime.setHours(0, 0, 0, 0);

    const personVisibility = {};

    if (type === 'dtr') {
      // eslint-disable-next-line array-callback-return
      copy.map((person) => {
        if (this.memebrInclusionCheck(person, tempDtrTime)) {
          personVisibility[person.id] = false;
        } else {
          personVisibility[person.id] = person.isDisplay;
        }
      });
    } else {
      // eslint-disable-next-line array-callback-return
      copy.map((person) => {
        if (
          (localStorage.getItem(person.id) !== 'RESTORED_' + person.id &&
            person.deletedDate &&
            parseInt(person.deletedDate) < tempDtrTime) ||
          (localStorage.getItem(person.id) && localStorage.getItem(person.id) < tempDtrTime)
        ) {
          personVisibility[person.id] = false;
        } else {
          personVisibility[person.id] = person.isDisplay;
        }
      });
    }

    updateDtrInclusions(bearer, token, tenantId, tempDtrTime.getTime() * 1, personVisibility);
  };

  memebrInclusionCheck = (person, tempDtrTime) => {
    return (
      (localStorage.getItem(person.id) !== 'RESTORED_' + person.id &&
        person.deletedDate &&
        parseInt(person.deletedDate) <= tempDtrTime) ||
      (localStorage.getItem(person.id) && localStorage.getItem(person.id) <= tempDtrTime)
    );
  };

  onOrderMemberApply = (copy) => {
    const { bearer, token, tenantId } = this.props;
    this.setState({ members: copy });

    const personOrder = {};

    // eslint-disable-next-line array-callback-return
    copy.map((person, index) => {
      personOrder[person.id] = index;
    });

    updateDtrOrdering(bearer, token, tenantId, this.state.selectedDepartment, personOrder);
  };

  getDtrMembersData = () => {
    const input = this.dtrGridRef?.state?.membersInput;

    let tempDtrTime = new Date(this.state.dtrDateTime);
    tempDtrTime.setHours(0, 0, 0, 0);

    if (input) {
      return this.state.members
        .filter((member) => {
          if (this.memebrInclusionCheck(member, tempDtrTime)) {
            return false;
          } else {
            return member.isDisplay;
          }
        })
        .map((member) => {
          return {
            id: member.id,
            name: member.name,
            ...input[member.id],
          };
        });
    }
  };

  render() {
    const { community } = this.props;

    const {
      selectedDepartment,
      departments,
      dtrDateTime,
      members,
      isLoading,
      showMemberManagementModal,
      showOrderMemberModal,
      showDailySubmitDialog,
      showWeeklySubmitDialog,
      submitOpen,
      submitAnchorEl,
      datePickerOpen,
      isLoginOut,
      departmentNotes,
    } = this.state;

    return (
      <React.Fragment>
        <div className="stickyHeader">
          <div className="stickyLeft">
            <IconButton onClick={this.handleLogout}>
              <LogoutIcon style={{ transform: 'rotate(-180deg)' }} />
            </IconButton>
          </div>
          <div className="dateContainer">
            <IconButton onClick={(event) => this.handleChangeDate(event, -1)} ref={(input) => (this.backBtn = input)}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                open={datePickerOpen}
                onOpen={() => this.handleDatePickerOpen(true)}
                onClose={() => this.handleDatePickerOpen(false)}
                value={new Date(this.state.dtrDateTime)}
                onChange={(newValue) => {
                  this.handleDatePickerDateChange(newValue);
                }}
                renderInput={({ inputRef, inputProps }) => (
                  <Box sx={{ display: 'hidden', alignItems: 'center' }}>
                    <a
                      ref={inputRef}
                      {...inputProps}
                      className="dateLink"
                      onClick={(e) => this.handleDatePickerOpen(true)}
                    >
                      {this.formatDtrDate()}
                    </a>
                  </Box>
                )}
              />
            </LocalizationProvider>
            <IconButton onClick={(event) => this.handleChangeDate(event, 1)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div className="submitButton stickyRight">
            <Button
              id="submit-btn"
              aria-controls={submitOpen ? 'submit-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={submitOpen ? 'true' : undefined}
              variant="contained"
              color="success"
              onClick={this.handleSubmitMenuClick}
            >
              Submit
            </Button>
            <Menu
              id="submit-menu"
              anchorEl={submitAnchorEl}
              open={submitOpen}
              onClose={this.handleSubmitMenuClick}
              MenuListProps={{
                'aria-labelledby': 'submit-btn',
              }}
            >
              <MenuItem
                onClick={(event) => {
                  this.handleSubmitMenuClick(event);
                  this.handleShowDailySubmitDialog(true);
                }}
              >
                Submit Daily
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  this.handleSubmitMenuClick(event);
                  this.handleShowWeeklySubmitDialog(true);
                }}
              >
                Submit Weekly
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="departmentsMembersContainer">
          <Typography variant="h5" gutterBottom component="div" className="communityName">
            {community.name}
          </Typography>
          <Typography variant="h6" gutterBottom component="div">
            Daily Time Report
          </Typography>
          <div>
            {departments?.items.length > 0 && (
              <TextField
                size="small"
                label="Departments"
                select
                sx={{ my: '8px' }}
                value={selectedDepartment}
                onChange={(event) => this.handleChangeDepartment(event)}
              >
                {departments.items.map((item, index) => (
                  <MenuItem value={item.properties.id} key={index}>
                    {item.properties.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </div>
          <Box>
            <Button variant="contained" onClick={() => this.props.setNewDTR(true)}>
              Switch To New Design
            </Button>
          </Box>
          <Box
            sx={{
              width: 300,
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="text"
              size="small"
              style={{ color: 'black', minWidth: '125px' }}
              onClick={() => this.setShowMemberManagementModal(true)}
            >
              Add Members
            </Button>
            <Button
              variant="text"
              size="small"
              style={{ color: 'black', minWidth: '125px' }}
              onClick={() => this.setShowOrderMemberModal(true)}
            >
              Order Members
            </Button>
          </Box>
        </div>
        <DTRGrid
          {...this.props}
          ref={(ref) => {
            this.dtrGridRef = ref;
          }}
          members={members}
          isLoading={isLoading}
          dtrDateTime={dtrDateTime}
          inclusionCheck={this.memebrInclusionCheck}
        />
        <div className="notesContainer">
          <TextField
            label=""
            multiline
            rows={4}
            value={departmentNotes}
            placeholder="Type your note here..."
            variant="outlined"
            fullWidth
            onChange={this.handleNotesChange}
            onBlur={this.handleSaveDepartmentNote}
          />
        </div>
        <DtrMemberManagementModal
          show={showMemberManagementModal}
          setShow={this.setShowMemberManagementModal}
          members={members}
          dtrDateTime={dtrDateTime}
          onAddPerson={this.onAddPerson}
          onApply={this.onManagementOptionsApply}
          inclusionCheck={this.memebrInclusionCheck}
        />
        <DtrOrderMemberModal
          show={showOrderMemberModal}
          setShow={this.setShowOrderMemberModal}
          members={members}
          dtrDateTime={dtrDateTime}
          onApply={this.onOrderMemberApply}
          inclusionCheck={this.memebrInclusionCheck}
        />
        <DailySubmitDialog
          open={showDailySubmitDialog}
          onClose={() => this.handleShowDailySubmitDialog(false)}
          onSubmit={() => this.handleSubmitDaily()}
          tableData={this.getDtrMembersData()}
          showOptional={this.dtrGridRef?.state?.showOptional}
        />
        <WeeklySubmitDialog
          cachedSetting={currFormat}
          open={showWeeklySubmitDialog}
          onClose={() => this.handleShowWeeklySubmitDialog(false)}
          onSubmit={(startOfWeek, format) => this.handleSubmitWeekly(startOfWeek, format)}
        />
        {isLoginOut ? <LoadingSpinner /> : ''}
      </React.Fragment>
    );
  }
}

export { DailyTimeReport };
