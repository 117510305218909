import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

import Modal, { ModalBody, ModalFooter, ModalHeader } from '../modal';
import CreatePersonDialog from '../createPersonDialog';
import CustomDialog from '../customDialog';
import './index.css';

import { handleDeletedDate } from '../../services/dtrService.js';
import useToken from '../../hooks/useToken.js';
import useTenant from '../../hooks/useTenant.js';
import useBearer from '../../hooks/useBearer.js';

const DtrMemberManagementModal = (props) => {
  const { token } = useToken();
  const { tenantId } = useTenant();
  const { bearer } = useBearer();

  const [membersCopy, setMembersCopy] = useState([]);
  const [showAddPerson, setShowAddPerson] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showAllreadyRemoved, setAllreadyRemoved] = useState(false);
  const [deletedMember, setDeletedMember] = useState(null);

  let tempDtrTime = new Date(props.dtrDateTime);
  tempDtrTime.setHours(0, 0, 0, 0);
  tempDtrTime = tempDtrTime.getTime();

  useEffect(() => {
    setMembersCopy(cloneDeep(props.members));
  }, [props.members, props.dtrDateTime]);

  const createContent = () => {
    let tempArr = cloneDeep(membersCopy);
    tempArr = tempArr.filter((member) => {
      if (props.inclusionCheck(member, tempDtrTime)) {
        return false;
      } else {
        return true;
      }
    });
    return tempArr.map((member, index) => createMemberRow(member, index));
  };

  const createMemberRow = (member, index) => {
    return (
      <div className="memberRow" key={index}>
        <div className="actions">
          <IconButton onClick={(event) => handleToggleVisibility(event, member)}>
            {member.isDisplay ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </div>

        <div className="memberName">
          <Typography variant="h6" gutterBottom component="p">
            {member.name}
          </Typography>
        </div>

        <div className="actions">
          {!member.id.startsWith('PERSON_') && (
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                setDeletedMember(member);
                let valInLocal = localStorage.getItem(member.id);

                if (valInLocal === 'RESTORED_' + member.id || (!valInLocal && !member.deletedDate)) {
                  handleDeleteConfirmDialog(true);
                } else {
                  handleAlreadyRemovedDialogue(true);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  };

  const handleApply = () => {
    props.onApply(membersCopy, tempDtrTime);
    props.setShow(false);
  };

  const handleDeleteConfirm = () => {
    props.onApply(membersCopy);
  };

  const handleToggleVisibility = (event, member) => {
    event.preventDefault();
    const newMembersOrder = membersCopy;
    const index = newMembersOrder.findIndex((elem) => elem.id === member.id);

    if (index !== -1 && index < newMembersOrder.length) {
      newMembersOrder[index].isDisplay = !newMembersOrder[index].isDisplay;
    }

    setMembersCopy([...newMembersOrder]);
  };

  const handleDeleteMember = async () => {
    if (deletedMember !== null && deletedMember.id) {
      let nextWeek = new Date();
      nextWeek.setDate(new Date(props.dtrDateTime).getDate() + 6); //Date when user will be removed from database

      let resp = await handleDeletedDate(
        bearer,
        token,
        tenantId,
        deletedMember.id,
        tempDtrTime,
        nextWeek.setHours(0, 0, 0, 0)
      );

      if (resp.responseCode === '0') {
        const newMembersCopy = membersCopy;
        const index = newMembersCopy.findIndex((elem) => elem.id === deletedMember.id);

        if (index !== -1 && index < newMembersCopy.length) {
          //Same as for restoring the user, we store a flag localy.
          localStorage.setItem(deletedMember.id, tempDtrTime);
        }

        await setMembersCopy([...newMembersCopy], handleDeleteConfirm());
        setDeletedMember(null);
      } else {
        toast.error(`Error while deleting ${deletedMember.name}`);
        await setDeletedMember(null);
      }

      setShowDeleteConfirm(false);
      handleAlreadyRemovedDialogue(false);
      handleDeleteConfirm();
    } else if (deletedMember !== null) {
      toast.error(`Unexpected error`);
    }
  };

  const restoreUser = async () => {
    let resp = await handleDeletedDate(
      bearer,
      token,
      tenantId,
      deletedMember.id,
      new Date().setHours(0, 0, 0, 0),
      new Date().setHours(0, 0, 0, 0)
    );

    if (resp.responseCode === '0') {
      toast.success(`Member restored`);
      deletedMember.isDisplay = true;

      //Localy flaging that the user has been restored.
      //We do that as the usr gets updated on the backend, but will only show that it was updated
      //next time we fetch it; i.e: on department change,
      localStorage.setItem(deletedMember.id, 'RESTORED_' + deletedMember.id);
    } else {
      toast.error(`Unexpected error`);
    }

    setDeletedMember(null);
    setAllreadyRemoved(false);
    handleDeleteConfirm();
  };

  const handleAddPersonDialog = (isShown) => {
    setShowAddPerson(isShown);
  };

  const handleDeleteConfirmDialog = (isShown) => {
    setShowDeleteConfirm(isShown);
    if (!isShown) {
      setDeletedMember(null);
    }
  };

  const handleAlreadyRemovedDialogue = (isShown) => {
    setAllreadyRemoved(isShown);
    if (!isShown) {
      setDeletedMember(null);
    }
  };

  const memberDeleteMessage = () => {
    return (
      <Typography variant="h6" gutterBottom component="p">
        <b>{deletedMember?.name || 'Member'}</b> will be permanently removed from the system.
      </Typography>
    );
  };
  //Individual already removed
  const alreadyRemovedMessage = () => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    let deletedDate;
    let dateToPermanentlyRemove;

    if (deletedMember) {
      if (deletedMember.deletedDate) {
        deletedDate = new Date(deletedMember.deletedDate * 1).toLocaleDateString(undefined, options);
        dateToPermanentlyRemove = new Date(deletedMember.dateToPermanentlyRemove * 1).toLocaleDateString(
          undefined,
          options
        );
      } else {
        deletedDate = new Date(localStorage.getItem(deletedMember.id) * 1);
        dateToPermanentlyRemove = new Date();
        dateToPermanentlyRemove.setDate(new Date(deletedDate).getDate() + 6);

        dateToPermanentlyRemove = dateToPermanentlyRemove.toLocaleDateString(undefined, options);
        deletedDate = deletedDate.toLocaleDateString(undefined, options);
      }
    }

    return (
      <Typography variant="h6" gutterBottom component="p">
        <b>{deletedMember?.name || 'Member'}</b> has already been removed from all the reports after
        <b> {deletedDate}</b>. This user will be permanently removed from the system on
        <b> {dateToPermanentlyRemove}</b>. Press the button below if you wish to restore this member.
      </Typography>
    );
  };

  return (
    <React.Fragment>
      <Modal show={props.show} setShow={props.setShow} styleClass="managementOptionsContainer">
        <ModalHeader>
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom component="p">
              Include in Report
            </Typography>
            <IconButton style={{ position: 'absolute', right: '8px' }} onClick={() => handleAddPersonDialog(true)}>
              <PersonAddIcon />
            </IconButton>
          </Box>
          <Divider />
        </ModalHeader>
        <ModalBody>
          <div className="managementOptionsContainer">{createContent()}</div>
        </ModalBody>
        <ModalFooter>
          <div className="modalButtonContainer">
            <Button variant="contained" color="success" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <CreatePersonDialog
        open={showAddPerson}
        onClose={() => handleAddPersonDialog(false)}
        onAddPerson={props.onAddPerson}
      />
      {/* Delete Member Dialog */}
      <CustomDialog
        open={showDeleteConfirm}
        onClose={() => handleDeleteConfirmDialog(false)}
        fullWidth={true}
        maxWidth="sm"
        title="Remove Department Member"
        body={memberDeleteMessage()}
        confirmCallback={handleDeleteMember}
        confirmButtonMessage="Delete"
      />
      {/* User already removed modal */}
      <CustomDialog
        open={showAllreadyRemoved}
        onClose={() => handleAlreadyRemovedDialogue(false)}
        fullWidth={true}
        maxWidth="sm"
        title="User Already Removed"
        body={alreadyRemovedMessage()}
        confirmCallback={restoreUser}
        confirmButtonMessage="Restore"
      />
    </React.Fragment>
  );
};

DtrMemberManagementModal.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  members: PropTypes.array,
  onAddPerson: PropTypes.func.isRequired,
};

export default DtrMemberManagementModal;
