import { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import CustomDialog from '../customDialog';
import './index.css';

const CreatePersonDialog = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [error, setError] = useState(false);

  const handleApply = () => {
    if (firstName || lastName) {
      setError(false);
      props.onAddPerson(firstName, lastName, jobTitle);
      props.onClose();
    } else {
      setError(true);
    }
  };

  const handleJobTitle = (val) => {
    setJobTitle(val);
  };

  const createFormBody = () => {
    return (
      <div className="addPersonForm">
        <TextField
          error={error && !firstName.length && !lastName.length}
          name="fName"
          label="First Name"
          variant="filled"
          size="small"
          InputLabelProps={{ style: { fontSize: 12 } }}
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <TextField
          error={error && !firstName.length && !lastName.length}
          name="lName"
          label="Last Name"
          variant="filled"
          size="small"
          InputLabelProps={{ style: { fontSize: 12 } }}
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
        <TextField
          name="jTitle"
          label="Job Title"
          variant="filled"
          size="small"
          InputLabelProps={{ style: { fontSize: 12 } }}
          value={jobTitle}
          onChange={(event) => handleJobTitle(event.target.value)}
        />
      </div>
    );
  };

  return (
    <CustomDialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="sm"
      title="Add Department Member"
      contentText="This contact's information will be restricted to time reports."
      body={createFormBody()}
      confirmCallback={handleApply}
      confirmButtonMessage="Create"
    />
  );
};

CreatePersonDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddPerson: PropTypes.func.isRequired,
};

export default CreatePersonDialog;
