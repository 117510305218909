import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { cloneDeep } from 'lodash';

import Modal, { ModalBody, ModalFooter, ModalHeader } from '../modal';
import './index.css';

const DtrOrderMemberModal = (props) => {
  const [membersCopy, setMembersCopy] = useState([]);

  let tempDtrTime = new Date(props.dtrDateTime);
  tempDtrTime.setHours(0, 0, 0, 0);

  useEffect(() => {
    setMembersCopy(cloneDeep(props.members));
  }, [props.members, props.dtrDateTime]);

  const createContent = () => {
    let tempArr = cloneDeep(membersCopy);
    tempArr = tempArr.filter((member) => {
      if (props.inclusionCheck(member, tempDtrTime)) {
        return false;
      } else {
        return true;
      }
    });
    return tempArr.map((member, index) => createMemberRow(member, index));
  };

  const createMemberRow = (member, index) => {
    return (
      <div className="memberRow" key={index}>
        <div className="actions">
          <IconButton
            onClick={(event) => {
              handleOrder(event, index, index - 1);
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </div>

        <div className="memberName">
          <Typography variant="h6" gutterBottom component="p">
            {member.name}
          </Typography>
        </div>

        <div className="actions">
          <IconButton
            onClick={(event) => {
              handleOrder(event, index, index + 1);
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const handleApply = () => {
    props.onApply(membersCopy);
    props.setShow(false);
  };

  const handleOrder = (event, from, to) => {
    event.preventDefault();

    if (to >= 0) {
      const newMembersOrder = membersCopy;
      const tmp = newMembersOrder[from];
      newMembersOrder[from] = newMembersOrder[to];
      newMembersOrder[to] = tmp;

      setMembersCopy([...newMembersOrder]);
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.show} setShow={props.setShow} styleClass="managementOptionsContainer">
        <ModalHeader>
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom component="p">
              Order Members
            </Typography>
          </Box>
          <Divider />
        </ModalHeader>
        <ModalBody>
          <div className="managementOptionsContainer">{createContent()}</div>
        </ModalBody>
        <ModalFooter>
          <div className="modalButtonContainer">
            <Button variant="contained" color="success" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

DtrOrderMemberModal.prototype = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  members: PropTypes.array,
  onOrderMember: PropTypes.func.isRequired,
  onAddPerson: PropTypes.func.isRequired,
};

export default DtrOrderMemberModal;
