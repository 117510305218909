import { Box, Button, IconButton, Typography } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
  Upgrade,
  CallTime,
  MealStart,
  MealEnd,
  Wrap,
  Kit,
  SecondMealStart,
  SecondMealEnd,
  TravelStart,
  TravelEnd,
} from '../../assets/icons/iconContainer';
import ValueAndEmptyStringValidator from '../../helperFunctions/emptyStringValidator';

const FinalConfirmSlider = ({
  memberDataContainer,
  item,
  edit,
  setIndex,
  tabs,
  notesText,
  sliderText,
  index,
  handleModalClose,
  handleEdit,
  setOpenDataModal,
  showTableView,
  formatTimeValues,
}) => {
  const createFinalSliderData = () => {
    let memberId = item.id;

    if (memberDataContainer.hasOwnProperty(memberId)) {
      return (
        <Box
          sx={{
            padding: '5px 5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            columnGap: '15px',
            rowGap: '10px',
            flex: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', columnGap: '15px', rowGap: '5px' }}>
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'call_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  setIndex(0);
                }}
              >
                <CallTime edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['call_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'first_meal_start_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  setIndex(1);
                }}
              >
                <MealStart edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['first_meal_start_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'first_meal_end_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  setIndex(2);
                }}
              >
                <MealEnd edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['first_meal_end_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'wrap_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  setIndex(3);
                }}
              >
                <Wrap edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['wrap_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'travel_start_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  sliderText.forEach((item) => {
                    if (item.backendCallValue === 'travel_start_time' && item.use) {
                      setIndex(5);
                    }
                  });
                }}
              >
                <TravelStart edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['travel_start_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'travel_end_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  sliderText.forEach((item) => {
                    if (item.backendCallValue === 'travel_start_time' && item.use) {
                      setIndex(6);
                    }
                  });
                }}
              >
                <TravelEnd edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['travel_end_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'second_meal_start_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  if (edit) {
                    let trigger = false;
                    let triggerTwo = false;
                    sliderText.forEach((item) => {
                      if (item.backendCallValue === 'travel_start_time' && item.use) {
                        trigger = true;
                      }
                      if (item.backendCallValue === 'second_meal_start_time' && item.use) {
                        triggerTwo = true;
                      }
                    });
                    if (trigger && triggerTwo) {
                      setIndex(7);
                    } else if (triggerTwo) {
                      setIndex(5);
                    }
                  }
                }}
              >
                <SecondMealStart edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['second_meal_start_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'second_meal_end_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  if (edit) {
                    let trigger = false;
                    let triggerTwo = false;
                    sliderText.forEach((item) => {
                      if (item.backendCallValue === 'travel_start_time' && item.use) {
                        trigger = true;
                      }
                      if (item.backendCallValue === 'second_meal_start_time' && item.use) {
                        triggerTwo = true;
                      }
                    });
                    if (trigger && triggerTwo) {
                      setIndex(8);
                    } else if (triggerTwo) {
                      setIndex(6);
                    }
                  }
                }}
              >
                <SecondMealEnd edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['second_meal_end_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'kit_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  rowGap: '5px',
                  height: '45px',
                }}
                onClick={() => {
                  if (edit) {
                    let triggerOne = false;
                    let triggerTwo = false;
                    notesText.forEach((item) => {
                      if (item.backendCallValue === 'upgrade_time' && item.use) {
                        triggerOne = true;
                      }
                      if (item.backendCallValue === 'kit_time' && item.use) {
                        triggerTwo = true;
                      }
                    });

                    if (triggerOne && triggerTwo) setIndex(tabs - 4);
                    else if (triggerTwo) setIndex(tabs - 3);
                  }
                }}
              >
                <Kit edit={edit} />
                <Typography
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['kit_time'].value}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{ display: 'flex', rowGap: '10px', flexDirection: 'column', alignSelf: 'start', paddingBottom: '10px' }}
          >
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'upgrade_time') ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  columnGap: '5px',
                }}
                onClick={() => {
                  if (edit) {
                    notesText.forEach((item) => {
                      if (item.backendCallValue === 'upgrade_time' && item.use) {
                        setIndex(tabs - 3);
                      }
                    });
                  }
                }}
              >
                <Upgrade edit={edit} />
                <Typography
                  sx={{
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  {memberDataContainer[memberId]['upgrade_time'].value}
                </Typography>
              </Box>
            ) : null}
            {ValueAndEmptyStringValidator(memberDataContainer[memberId], 'other_notes') ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  columnGap: '5px',
                }}
                onClick={() => {
                  if (edit) {
                    setIndex(tabs - 2);
                  }
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: `${edit ? '#6C6D6BBD' : ''}`,
                  }}
                >
                  Notes: {memberDataContainer[memberId]['other_notes'].value}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      );
    } else return <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}></Box>;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '15% 1fr 15%',
          flex: 1,
        }}
      >
        <IconButton onClick={() => setIndex(index - 1)}>
          <ChevronLeftIcon sx={{ color: '#01aa25' }} />
        </IconButton>
        {createFinalSliderData()}
      </Box>
      <Box
        pl={3}
        pr={3}
        pb={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: '20px',
        }}
      >
        <Button
          fullWidth
          disableRipple
          variant="outlined"
          onClick={handleEdit}
          sx={{
            borderColor: 'black',
            color: 'black',
            textTransform: 'none',
            fontFamily: 'Inter-Bold',
            paddingTop: '5px',
            paddingBottom: '5px',
            '&:focus': {
              borderColor: 'black',
            },
          }}
        >
          Edit
        </Button>

        <Button
          fullWidth
          variant="contained"
          disableRipple
          onClick={() => {
            if (showTableView) {
              formatTimeValues();
              setOpenDataModal(false);
            } else {
              formatTimeValues();
              handleModalClose();
            }
          }}
          sx={{
            backgroundColor: 'black',
            textTransform: 'none',
            fontFamily: 'Inter-Bold',
            paddingTop: '5px',
            paddingBottom: '5px',
            '&:focus': {
              backgroundColor: 'black',
            },
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export default FinalConfirmSlider;
