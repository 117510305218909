import { Box, Typography, IconButton } from '@mui/material';
import { useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { removeTimeReportPerson, saveDailyTimeLogs } from '../../services/dtrService';

import DragnDrop from '../../assets/icons/dragndrop.svg';
import Trashcan from '../../assets/icons/trashcan.svg';
import Swal from 'sweetalert2';

import './index.css';

const OrderMembersComponentBar = ({
  item,
  memberDataContainer,
  setMemberDataContainer,
  bearer,
  token,
  tenantId,
  dtrDateTime,
  saveContentFormat,
  members,
  setMembers,
  setIsLoading,
}) => {
  const [orderMembersValue, setOrderMembersValue] = useState(
    memberDataContainer.hasOwnProperty(item.id) && memberDataContainer[item.id].hasOwnProperty('call_type')
      ? memberDataContainer[item.id].call_type.value
      : item.id.startsWith('PERSON_')
      ? 'weekly'
      : 'daily'
  );

  useEffect(() => {
    setOrderMembersValue(
      memberDataContainer.hasOwnProperty(item.id) && memberDataContainer[item.id].hasOwnProperty('call_type')
        ? memberDataContainer[item.id].call_type.value
        : item.id.startsWith('PERSON_')
        ? 'weekly'
        : 'daily'
    );
  }, [memberDataContainer]);

  const handleChangeOrderMembers = (orderValue) => {
    let memberId = item.id;

    setOrderMembersValue(orderValue);
    const newMemberDataContainerState = { ...memberDataContainer };
    let copyContent = {};
    let tempContent = {};
    if (!memberId.startsWith('PERSON_')) tempContent.call_type = 'daily';

    if (memberDataContainer.hasOwnProperty(memberId)) {
      tempContent = { ...saveContentFormat };
      copyContent = memberDataContainer[memberId];

      for (const [key, value] of Object.entries(copyContent)) {
        tempContent[key] = value.value;
      }

      tempContent.call_type = orderValue;
    } else {
      tempContent = { call_type: orderValue };
    }

    let storageContent = {};
    Object.keys(tempContent).forEach((item) => {
      storageContent[item] = { value: tempContent[item] };
    });

    newMemberDataContainerState[memberId] = storageContent;
    setMemberDataContainer(newMemberDataContainerState);

    saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, memberId, tempContent)
      .then((resp) => {
        resp.responseCode === '0'
          ? console.debug('Successfully saved user data')
          : console.error('Could not save user data');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteMember = (item) => {
    Swal.fire({
      title: 'REMOVE DEPARTMENT MEMBER',
      html: `${item.firstname} ${item.lastname} ${item.department} will be permanently removed from the system`,
      confirmButtonText: 'Cancel',
      cancelButtonText: 'Delete',
      showCancelButton: true,
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn confirm-order',
        cancelButton: 'btn cancel-order',
        htmlContainer: 'text-container',
        title: 'text-title',
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
        } else if (result.dismiss === 'cancel') {
          setIsLoading(true);
          removeTimeReportPerson(bearer, token, tenantId, item.id)
            .then((resp) => {
              let respSuccess = resp.responseCode === '0';
              setIsLoading(false);
              if (respSuccess) {
                console.debug('Successfully deleted user with id: ', item.id);
                let membersCopy = members.filter((member) => member.id !== item.id);
                setMembers(membersCopy);
              } else {
                console.error('Could not delete user with id: ', item.id);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '10% 25% 65%',
        columnGap: '15px',
        borderRadius: '7px',
        paddingLeft: '10px',
        paddingRight: '5px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
        <img src={DragnDrop} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', justifyContent: 'center' }}>
        <Typography
          sx={{
            fontFamily: 'Inter-Bold',
            fontSize: '13px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {item.firstname} {item.lastname}
        </Typography>
        <Typography sx={{ fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {item.department}
        </Typography>
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
        <IconButton
          onClick={() => handleChangeOrderMembers('weekly')}
          disableRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            textTransform: 'none',
          }}
        >
          <CalendarMonthIcon
            sx={{ color: '#D9D9D9', fontSize: '22px' }}
            className={orderMembersValue === 'weekly' ? 'selected-item' : ''}
          />
          <Typography
            sx={{ color: '#D9D9D9', fontSize: '13px' }}
            className={orderMembersValue === 'weekly' ? 'selected-item' : ''}
          >
            Weekly
          </Typography>
        </IconButton>
        <IconButton
          onClick={() => handleChangeOrderMembers('daily')}
          disableRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            textTransform: 'none',
          }}
        >
          <CalendarTodayIcon
            sx={{ color: '#D9D9D9', fontSize: '22px' }}
            className={orderMembersValue === 'daily' ? 'selected-item' : ''}
          />
          <Typography
            sx={{ color: '#D9D9D9', fontSize: '13px' }}
            className={orderMembersValue === 'daily' ? 'selected-item' : ''}
          >
            Daily
          </Typography>
        </IconButton>
        <IconButton
          onClick={() => handleChangeOrderMembers('not-in')}
          disableRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            textTransform: 'none',
          }}
        >
          <CloseIcon
            sx={{ color: '#D9D9D9', fontSize: '22px' }}
            className={orderMembersValue === 'not-in' ? 'selected-item' : ''}
          />
          <Typography
            sx={{ color: '#D9D9D9', fontSize: '13px' }}
            className={orderMembersValue === 'not-in' ? 'selected-item' : ''}
          >
            Not In
          </Typography>
        </IconButton>
        {/* Trashcasn temporarily removed because it deletes the time_report_person in the backend currently */}
        {/* 
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          {!item.id.startsWith('PERSON_') ? (
            <IconButton onClick={() => handleDeleteMember(item)}>
              <img src={Trashcan} style={{ width: '18px' }} />
            </IconButton>
          ) : null} 
          </Box>*/}
      </Box>
    </Box>
  );
};

export default OrderMembersComponentBar;
