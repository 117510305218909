import { Box, Typography, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DataModal from '../dataModal';
import EditIcon from '@mui/icons-material/Edit';

import {
  CallTime,
  MealStart,
  MealEnd,
  TravelStart,
  TravelEnd,
  Wrap,
  SecondMealStart,
  SecondMealEnd,
  Kit,
  Upgrade,
} from '../../assets/icons/iconContainer';
import ValueAndEmptyStringValidator from '../../helperFunctions/emptyStringValidator';

const MemberDataRow = ({
  item,
  showTableView,
  setIsLoading,
  applyToAll,
  applyToNext,
  itemID,
  members,
  bearer,
  token,
  tenantId,
  dtrDateTime,
  memberDataContainer,
  displayToast,
  saveContentFormat,
  setMemberDataContainer,
}) => {
  const [openDataModal, setOpenDataModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showOverflowingNotes, setShowOverflowingNotes] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [edit, setEdit] = useState(false);
  const [hideUser, setHideUser] = useState(false);

  useEffect(() => {
    let memberId = item.id;

    if (memberNotIn(memberId)) {
      setHideUser(true);
    } else {
      setHideUser(false);
    }

    if (memberDataContainer.hasOwnProperty(memberId)) {
      for (const [key, value] of Object.entries(memberDataContainer[memberId])) {
        if (key != 'call_type' && ValueAndEmptyStringValidator(value)) {
          setConfirm(true);
        }
      }
    } else {
      setConfirm(false);
    }
  }, [memberDataContainer]);

  // If a member is marked as not-in, hide them in the view so the DTR member filling out the times will not apply them to the not-in member.

  const memberNotIn = (memberId) => {
    if (memberDataContainer.hasOwnProperty(memberId) && memberDataContainer[memberId].hasOwnProperty('call_type')) {
      if (memberDataContainer[memberId].call_type.value === 'not-in') {
        return true;
      }
    }

    return false;
  };

  const handleModalClose = () => {
    setEdit(false);

    let memberId = item.id;
    let confirm = false;
    if (memberDataContainer.hasOwnProperty(memberId)) {
      for (const [key, value] of Object.entries(memberDataContainer[memberId])) {
        if (key !== 'call_type' && ValueAndEmptyStringValidator(value)) {
          confirm = true;
        }
      }
    }

    setConfirm(confirm);
    setOpenDataModal(false);
  };

  const [sliderText, setSliderText] = useState([
    { id: 0, title: 'Call Time', use: true, backendCallValue: 'call_time', component: <CallTime /> },
    {
      id: 1,
      title: 'Meal Start',
      use: true,
      backendCallValue: 'first_meal_start_time',
      component: <MealStart />,
    },
    {
      id: 2,
      title: 'Meal End',
      use: true,
      backendCallValue: 'first_meal_end_time',
      component: <MealEnd edit={edit} />,
    },
    { id: 3, title: 'Wrap', use: true, backendCallValue: 'wrap_time', component: <Wrap /> },
    {
      id: 4,
      title: 'Travel Start',
      use: false,
      backendCallValue: 'travel_start_time',
      component: <TravelStart />,
    },
    {
      id: 5,
      title: 'Travel End',
      use: false,
      backendCallValue: 'travel_end_time',
      component: <TravelEnd />,
    },
    {
      id: 6,
      title: '2nd Meal Start',
      use: false,
      backendCallValue: 'second_meal_start_time',
      component: <SecondMealStart />,
    },
    {
      id: 7,
      title: '2nd Meal End',
      use: false,
      backendCallValue: 'second_meal_end_time',
      component: <SecondMealEnd />,
    },
    { id: 8, title: 'Kit', use: false, backendCallValue: 'kit_time', component: <Kit /> },
  ]);

  const [notesText, setNotesText] = useState([
    { id: 0, title: 'Kit', use: false, backendCallValue: 'kit_time' },
    { id: 1, title: 'Upgrade', use: false, backendCallValue: 'upgrade_time' },
    { id: 2, title: 'Notes', use: true, backendCallValue: 'other_notes' },
  ]);

  const createFinalSliderData = () => {
    let memberId = item.id;

    if (memberDataContainer.hasOwnProperty(memberId)) {
      return (
        <Box
          mt={1}
          sx={{
            padding: '10px 5px',
            display: 'flex',
            flexDirection: 'column',
            columnGap: '15px',
            rowGap: '5px',
            flex: 1,
          }}
        >
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 15%)', rowGap: '10px' }}>
            {sliderText.map((item, index) =>
              memberDataContainer[memberId].hasOwnProperty(item.backendCallValue) &&
              memberDataContainer[memberId][item.backendCallValue].hasOwnProperty('value') &&
              memberDataContainer[memberId][item.backendCallValue].value !== '-1' &&
              memberDataContainer[memberId][item.backendCallValue].value !== null &&
              memberDataContainer[memberId][item.backendCallValue].value !== '-' &&
              memberDataContainer[memberId][item.backendCallValue].value !== '' ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    rowGap: '5px',
                    height: '45px',
                  }}
                >
                  {item.component}
                  <Typography sx={{ fontSize: '10px' }}>
                    {memberDataContainer[memberId][item.backendCallValue].value}
                  </Typography>
                </Box>
              ) : null
            )}
          </Box>
          <Box
            sx={{ display: 'flex', rowGap: '10px', flexDirection: 'column', paddingTop: '10px', paddingLeft: '10px' }}
          >
            {memberDataContainer[memberId].hasOwnProperty('upgrade_time') &&
            memberDataContainer[memberId]['upgrade_time'].hasOwnProperty('value') &&
            memberDataContainer[memberId]['upgrade_time'].value !== '-1' &&
            memberDataContainer[memberId]['upgrade_time'].value !== '-' &&
            memberDataContainer[memberId]['upgrade_time'].value !== '' &&
            memberDataContainer[memberId]['upgrade_time'].value !== null ? (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '5px' }}>
                <Upgrade />
                <Typography sx={{ fontSize: '12px' }}>{memberDataContainer[memberId]['upgrade_time'].value}</Typography>
              </Box>
            ) : null}
            {memberDataContainer[memberId]['other_notes'] !== undefined &&
            memberDataContainer[memberId]['other_notes'].hasOwnProperty('value') &&
            memberDataContainer[memberId]['other_notes'].value !== '-1' &&
            memberDataContainer[memberId]['other_notes'].value !== '' &&
            memberDataContainer[memberId]['other_notes'].value != null &&
            memberDataContainer[memberId]['other_notes'].value !== '-' ? (
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                  ref={(ref) => {
                    if (ref && ref.offsetHeight > 20) {
                      console.debug(ref.offsetHeight);
                      setShowReadMore(true);
                    }
                  }}
                  sx={{
                    fontSize: '12px',
                    overflow: `${showReadMore && !showOverflowingNotes ? 'hidden' : ''}`,
                    textOverflow: `${showReadMore && !showOverflowingNotes ? 'ellipsis' : ''}`,
                    whiteSpace: `${showReadMore && !showOverflowingNotes ? 'nowrap' : ''}`,
                  }}
                >
                  Notes: {memberDataContainer[memberId]['other_notes'].value}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              display: `${showReadMore ? 'flex' : 'none'}`,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: '#01aa25',
              }}
              onClick={() => {
                setShowOverflowingNotes((prev) => !prev);
              }}
            >
              {!showOverflowingNotes ? 'Read More' : 'Read Less'}
            </Typography>
          </Box>
        </Box>
      );
    } else return null;
  };

  const handleEdit = () => {
    if (!openDataModal) {
      setOpenDataModal(true);
    }

    if (memberDataContainer.hasOwnProperty(item.id)) {
      let trigger = false;

      Object.values(memberDataContainer[item.id]).forEach((obj, index) => {
        if (Object.keys(memberDataContainer[item.id])[index] == 'call_type') {
        } else if (ValueAndEmptyStringValidator(obj)) trigger = true;
      });

      if (trigger) {
        displayToast('Select an icon in the card to edit', false);
        setEdit(true);
      } else {
        displayToast('No cards available to edit', false);
        setEdit(false);
      }
    }
  };

  return (
    <>
      {!hideUser ? (
        !confirm ? (
          <Box
            sx={{
              border: '1px solid black',
              borderRadius: '7px',
              backgroundColor: '#F9F6F6',
              boxShadow: '0px 2px 8px 2px rgba(0, 0, 0, 0.15)',
            }}
          >
            <Box
              onClick={() => setOpenDataModal(true)}
              sx={{
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(0deg)',
                },
                padding: '6px',
                paddingLeft: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  fontSize: '15px',
                  width: 'auto',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                <b style={{ fontFamily: 'Inter-Bold' }}>
                  {item.firstname} {item.lastname}
                </b>{' '}
                - {item.department}
              </Typography>
              <IconButton sx={{ padding: 0 }}>
                <AddIcon sx={{ color: '#01AA25' }} />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              padding: '0 10px',
              backgroundColor: '#F9F6F6',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '98px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 3px',
            }}
          >
            <Box sx={{ backgroundColor: '#F9F6F6' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  pl={1}
                  pt={1}
                  sx={{
                    fontSize: '15px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '80%',
                  }}
                >
                  <b style={{ fontFamily: 'Inter-Bold' }}>
                    {item.firstname} {item.lastname}
                  </b>{' '}
                  - {item.department}
                </Typography>
                <Box onClick={handleEdit} sx={{ display: 'flex', alignItems: 'center', columnGap: '3px' }}>
                  <EditIcon sx={{ fontSize: '16px', color: '#01AA25', paddingTop: '6px' }} />
                  <Typography pr={1} pt={1} sx={{ fontSize: '14px', color: '#01AA25', fontFamily: 'Inter-Bold' }}>
                    Edit
                  </Typography>
                </Box>
              </Box>
            </Box>
            {createFinalSliderData()}
          </Box>
        )
      ) : (
        ''
      )}

      {hideUser ? (
        ''
      ) : (
        <DataModal
          setConfirm={setConfirm}
          confirm={confirm}
          setIsLoading={setIsLoading}
          item={item}
          applyToAll={applyToAll}
          applyToNext={applyToNext}
          key={itemID}
          itemID={itemID}
          members={members}
          bearer={bearer}
          token={token}
          tenantId={tenantId}
          dtrDateTime={dtrDateTime}
          memberDataContainer={memberDataContainer}
          showTableView={showTableView}
          displayToast={displayToast}
          saveContentFormat={saveContentFormat}
          setMemberDataContainer={setMemberDataContainer}
          handleEdit={handleEdit}
          edit={edit}
          setEdit={setEdit}
          setOpenDataModal={setOpenDataModal}
          openDataModal={openDataModal}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default MemberDataRow;
