import { Typography, Box, TextField, Button, IconButton } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { saveDailyTimeLogs } from '../../services/dtrService';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  searchBarStyle: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '15px 0',
        fontSize: '20px',
        textAlign: 'center',
      },
      '& fieldset': {
        borderRadius: '8px',
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#01aa25',
        borderWidth: '2px',
      },
    },
  },
  buttonStyle: {
    '&:hover': {
      borderColor: 'black',
    },
  },
}));

const NotesSlider = ({
  bearer,
  token,
  tenantId,
  dtrDateTime,
  text,
  item,
  applyToNext,
  applyToAll,
  itemID,
  backendCallValue,
  saveContentFormat,
  memberDataContainer,
  setMemberDataContainer,
  notesText,
  index,
  setIndex,
}) => {
  const [value, setValue] = useState('');

  const classes = useStyles();

  const onTextChange = (e) => {
    setValue(e.target.value);
  };

  const handleTextChange = () => {
    let memberId = item.id;
    let copyContent = {};
    if (memberDataContainer.hasOwnProperty(memberId)) copyContent = memberDataContainer[memberId];

    let tempContent = { ...saveContentFormat };
    if (!memberId.startsWith('PERSON_')) tempContent.call_type = 'daily';

    for (const [key, value] of Object.entries(copyContent)) {
      tempContent[key] = value.value;
    }

    tempContent[backendCallValue] = value;

    if (tempContent.kit_time === '-1') tempContent.kit_time = '-';
    if (tempContent.upgrade_time === '-1') tempContent.upgrade_time = '-';

    let storageContent = {};
    Object.keys(tempContent).forEach((item) => {
      storageContent[item] = { value: tempContent[item] };
    });

    let newStateContainer = { ...memberDataContainer };
    newStateContainer[memberId] = storageContent;
    setMemberDataContainer(newStateContainer);

    saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, memberId, tempContent)
      .then((resp) => {
        resp.responseCode === '0'
          ? console.debug('Successfully saved user data')
          : console.error('Could not save user data');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateValues = () => {
    let memberId = item.id;

    if (memberDataContainer.hasOwnProperty(memberId)) {
      let desiredContent = memberDataContainer[memberId];
      if (desiredContent.hasOwnProperty(backendCallValue)) {
        setValue(desiredContent[backendCallValue].value);
      }
    }
  };

  useEffect(() => {
    updateValues();
  }, [memberDataContainer, notesText]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '30px',
        height: '100%',
      }}
    >
      <Box>
        <Typography mb={1} sx={{ fontSize: '15px', textAlign: 'center' }}>
          {text}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton onClick={() => setIndex(index - 1)}>
            <ChevronLeftIcon sx={{ color: '#01aa25' }} />
          </IconButton>
          <TextField
            className={classes.searchBarStyle}
            value={value === '-1' || value === '-' ? '' : value}
            onChange={onTextChange}
            sx={{ width: '100%' }}
            onBlur={handleTextChange}
          />
          <IconButton onClick={() => setIndex(index + 1)}>
            <ChevronRightIcon sx={{ color: '#01aa25' }} />
          </IconButton>
        </Box>
      </Box>
      <Box pb={1} sx={{ display: 'flex', justifyContent: 'center', columnGap: '35px' }}>
        <Button
          className={classes.buttonStyle}
          size="large"
          color="secondary"
          variant="outlined"
          sx={{
            textTransform: 'none',
            paddingTop: 0,
            paddingBottom: 0,
            fontSize: '13px',
            fontFamily: 'Inter-Bold',
            color: 'black',
            borderColor: 'black',
          }}
          onClick={() => {
            applyToAll(value, backendCallValue, text);
          }}
        >
          Apply To All
        </Button>
        <Button
          className={classes.buttonStyle}
          variant="outlined"
          color="secondary"
          sx={{
            textTransform: 'none',
            fontSize: '13px',
            fontFamily: 'Inter-Bold',
            color: 'black',
            borderColor: 'black',
          }}
          onClick={() => applyToNext(value, backendCallValue, itemID, text)}
        >
          Apply To Next
        </Button>
      </Box>
    </Box>
  );
};

export default NotesSlider;
