import Api from './api.js';

/**
 * If the user only has access to one community, then the communitiesResult.communitiesIdNameMap may be undefined.
 * @param {Object} communitiesResult
 * @returns
 */
function setCommunitiesIdNameMap(communitiesResult) {
  return communitiesResult.communitiesIdNameMap !== undefined
    ? localStorage.setItem('communitiesIdNameMap', JSON.stringify(communitiesResult.communitiesIdNameMap))
    : localStorage.setItem('communitiesIdNameMap', JSON.stringify({}));
}

export async function loginUser(username, password) {
  const payload = {
    username,
    password,
    xsrf: '8f869fd8b51bcdd4173168f17986d499f8e70ba9',
  };

  const loginResult = await Api.doLoginPost('LoginServicePWA', payload);
  const communitiesResult = await Api.doLoginPost(
    `loginservice?userId=${username}&password=${password}&xsrf=8f869fd8b51bcdd4173168f17986d499f8e70ba9`
  );

  if (loginResult && ['1', '2', 1, 2].includes(loginResult.status)) {
    // if 1 or 2 exists in login status that means that a user exists
    localStorage.setItem('newUser', false);
    setCommunitiesIdNameMap(communitiesResult);

    return {
      ...loginResult,
      tenantId: loginResult.defaultCommunity,
      community: {
        name: loginResult.defaultCommunityName,
      },
      ...communitiesResult,
    };
  } else if (loginResult && loginResult.status === 'lost password') {
    localStorage.setItem('newUser', true);
    setCommunitiesIdNameMap(communitiesResult);

    return {
      ...loginResult,
      tenantId: loginResult.defaultCommunity,
      community: {
        name: loginResult.defaultCommunityName,
      },
      ...communitiesResult,
    };
  } else {
    return null;
  }
}
