import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './dtr3-new/components/login';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import { DailyTimeReport } from './dtr3-old/pages/dailyTimeReport';
import DailyTimeReportV2 from './dtr3-new/pages/dailyTimeReportV2';
import { Photo } from './dtr3-old/pages/photo';
import ResetPassword from './dtr3-new/pages/resetPassword';

import useToken from './hooks/useToken.js';
import useTenant from './hooks/useTenant.js';
import useBearer from './hooks/useBearer.js';
import useCommunity from './hooks/useCommunity.js';

import './index.css';
import './fonts.css';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#aaa',
      contrastText: '#fff',
    },
  },
});

function App() {
  const { community, setCommunity } = useCommunity();
  const { token, setToken } = useToken();
  const { tenantId, setTenantId } = useTenant();
  const { bearer, setBearer } = useBearer();

  // newDTR determines which version of the DTR we present to the user

  const [newDTR, setNewDTR] = useState(true);
  const [newUser, setNewUser] = useState(localStorage.getItem('newUser'));

  const [communityMap, setCommunityMap] = useState(
    localStorage.getItem('communitiesIdNameMap') !== null
      ? localStorage.getItem('communitiesIdNameMap') !== undefined
        ? JSON.parse(localStorage.getItem('communitiesIdNameMap'))
        : {}
      : {}
  );

  const onLogin = async (token, tenantId, bearer, community, communitiesIdMap) => {
    await setCommunity(community);
    setTenantId(tenantId);
    setToken(token);
    setBearer(bearer);
    if (communitiesIdMap !== undefined) {
      /**
       * CommunitiesIdMap is a mapping between communities available for a user
       * and their ID's
       * Thus if it's undefined we want to keep the state variable as an empty object
       * implying that there are no communities for a user to switch between
       */
      setCommunityMap(communitiesIdMap);
    }
  };

  const onLogout = async () => {
    await setCommunity({});
    setTenantId(null);
    setToken(null);
    setBearer(null);
  };

  const getAppBody = () => {
    if (token === null || tenantId === null || bearer === null) {
      return <Login onLogin={onLogin} />;
    } else if (newUser == 'true') {
      // if new user (status === lost password)
      // then the user is prompted to set change his password
      // afterwards he can continue using the DTR
      return <ResetPassword setNewUser={setNewUser} token={token} tenantId={tenantId} bearer={bearer} />;
    } else {
      return (
        <Router>
          <Switch>
            <Route
              path="/"
              exact
              element={
                newDTR ? (
                  <DailyTimeReportV2
                    bearer={bearer}
                    token={token}
                    tenantId={tenantId}
                    community={community}
                    communityMap={communityMap}
                    onLogout={onLogout}
                    setTenantId={setTenantId}
                    setCommunity={setCommunity}
                    setNewDTR={setNewDTR}
                  />
                ) : (
                  <DailyTimeReport
                    bearer={bearer}
                    token={token}
                    tenantId={tenantId}
                    community={community}
                    onLogout={onLogout}
                    setTenantId={setTenantId}
                    setNewDTR={setNewDTR}
                  />
                )
              }
            />
            <Route
              path="/dtr"
              element={
                newDTR ? (
                  <DailyTimeReportV2
                    bearer={bearer}
                    token={token}
                    tenantId={tenantId}
                    community={community}
                    communityMap={communityMap}
                    onLogout={onLogout}
                    setTenantId={setTenantId}
                    setCommunity={setCommunity}
                    setNewDTR={setNewDTR}
                  />
                ) : (
                  <DailyTimeReport
                    bearer={bearer}
                    token={token}
                    tenantId={tenantId}
                    community={community}
                    onLogout={onLogout}
                    setTenantId={setTenantId}
                    setNewDTR={setNewDTR}
                  />
                )
              }
            />
            <Route path="/photo" element={<Photo />} />
          </Switch>
        </Router>
      );
    }
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {getAppBody()}
        <ToastContainer />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
