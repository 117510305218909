import React from 'react';
import { Box, Typography } from '@mui/material';

import busLeft from '../../assets/icons/BusLeft.svg';
import busRight from '../../assets/icons/BusRight.svg';
import utensilsUp from '../../assets/icons/utensilsUp.svg';
import utensilsDown from '../../assets/icons/utensilsDown.svg';
import megaphone from '../../assets/icons/Megaphone.svg';
import stopwatch from '../../assets/icons/stopwatch.svg';
import officeBag from '../../assets/icons/office bag.svg';
import arrowCircleUp from '../../assets/icons/arrow circle up.svg';

const IconLegend = () => {
  return (
    <>
      <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>Icon Legend</Typography>
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            width: '24px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <img style={{ height: '21px' }} src={megaphone}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>
            Call Time
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '18.25px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <img style={{ height: '20.29px' }} src={utensilsUp}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>
            Meal Start
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '18.25px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <img style={{ height: '20.29px' }} src={utensilsDown}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>
            Meal End
          </Typography>
        </Box>
        <Box
          pt={0.2}
          sx={{
            display: 'flex',
            width: '22px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            rowGap: '1.5px',
          }}
        >
          <img style={{ height: '17px' }} src={busRight}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>
            Travel Start
          </Typography>
        </Box>
        <Box
          pt={0.2}
          sx={{
            display: 'flex',
            width: '22px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            rowGap: '1.5px',
          }}
        >
          <img style={{ height: '17px' }} src={busLeft}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>
            Travel End
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '16px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <img style={{ height: '24px' }} src={stopwatch}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>
            Wrap
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '20px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <img style={{ height: '20.5px' }} src={officeBag}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>Kit</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '20px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <img style={{ height: '20px' }} src={arrowCircleUp}></img>
          <Typography sx={{ fontSize: '10px', display: 'flex', flexWrap: 'wrap', textAlign: 'center' }}>
            Upgrade
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default IconLegend;
