import Api from './api.js';

export async function getDepartment(bearer, token, tenantId) {
  return await Api.doGet('_ah/api/personapi/v2/fetchDepartments', bearer, { token, tenantId });
}

export async function getDepartmentForUI(bearer, token, tenantId) {
  return await Api.doGet('_ah/api/personapi/v2/fetchDepartmentsForDTRUI', bearer, { token, tenantId });
}

export async function getWTRSetting(bearer, token, tenantId) {
  return await Api.doGet('_ah/api/personapi/v2/fetchWTRsettingsChoice', bearer, { token, tenantId });
}

export async function saveWTRSetting(bearer, token, tenantId, choice, entKey) {
  return await Api.doPost('_ah/api/personapi/v2/saveWTRsettingsChoice', bearer, { token, tenantId, choice, entKey });
}

export async function getDepartmentMembers(bearer, token, tenantId, departmentId, dtrDateTime) {
  const [minTime, maxTime] = _getMinMaxTime(dtrDateTime);

  return await Api.doGet('_ah/api/personapi/v2/fetchDepartmentMembersTitle', bearer, {
    token,
    tenantId,
    departmentId,
    minTime,
    maxTime,
  });
}

export async function getUserDepartment(bearer, token, tenantId) {
  const response = await Api.doGet('_ah/api/personapi/v2/fetchUserDepartmentId', bearer, { token, tenantId });
  return response.responseMessage;
}

export async function fetchDTR(bearer, token, tenantId, departmentId) {
  return Api.doGet('_ah/api/personapi/v2/fetchDTR', bearer, { token, tenantId, departmentId });
}

export async function getDailyTimeLogs(bearer, token, tenantId, dtrDateTime, personIds) {
  const [minTime, maxTime] = _getMinMaxTime(dtrDateTime);

  const payload = {
    value: personIds,
  };

  const response = await Api.doPost(
    '_ah/api/personapi/v2/fetchDailyTimeLogs',
    bearer,
    { token, tenantId, minTime, maxTime, alt: 'json' },
    payload
  );
  return response;
}

export async function updateDtrInclusions(bearer, token, tenantId, dtrDateTime, personVisibility) {
  const [minTime, maxTime] = _getMinMaxTime(dtrDateTime);

  const payload = {
    props: personVisibility,
  };

  return Api.doPost(
    '_ah/api/personapi/v2/updateDtrInclusions',
    bearer,
    { token, tenantId, minTime, maxTime, targetReportTime: dtrDateTime },
    payload
  );
}

export async function updateDtrOrdering(bearer, token, tenantId, departmentId, personOrder) {
  const payload = {
    props: personOrder,
  };

  return Api.doPut(
    '_ah/api/personapi/v2/overwriteDTRPersonOrdering',
    bearer,
    { token, tenantId, departmentId },
    payload
  );
}

export async function addTimeReportPerson(bearer, token, tenantId, departmentId, firstName, lastName, jobTitle) {
  return Api.doPost('_ah/api/personapi/v2/addTimeReportPerson', bearer, {
    token,
    tenantId,
    departmentId,
    firstName,
    lastName,
    jobTitle,
  });
}

export async function saveDailyTimeLogs(bearer, token, tenantId, dtrDateTime, personIds, logs) {
  const payload = {
    props: {
      personIds,
      targetReportTime: dtrDateTime,
    },
  };

  Object.entries(logs).map(([type, value]) => {
    payload.props[type] = value;
  });

  return Api.doPost('_ah/api/personapi/v2/saveDailyTimeLogs', bearer, { token, tenantId }, payload);
}

export async function submitDailyTimeReport(bearer, token, tenantId, departmentId, dtrDateTime) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return Api.doPost('_ah/api/personapi/v2/submitDailyTimeReport', bearer, {
    token,
    tenantId,
    departmentId,
    targetReportTime: dtrDateTime,
    timezone,
  });
}

export async function submitWeeklyTimeReport(bearer, token, tenantId, departmentId, targetReportTime, reportType) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return Api.doPost('_ah/api/personapi/v2/submitWeeklyTimeReport', bearer, {
    token,
    tenantId,
    departmentId,
    reportType,
    targetReportTime,
    timeZone,
  });
}

export async function removeTimeReportPerson(bearer, token, tenantId, personId) {
  const payload = {
    value: personId,
  };

  return Api.doPost(
    '_ah/api/personapi/v2/removeTimeReportPerson',
    bearer,
    {
      token,
      tenantId,
    },
    payload
  );
}

export async function handleDeletedDate(bearer, token, tenantId, entID, dateDeleted, dateToPermanentlyRemove) {
  return await Api.doPost('_ah/api/personapi/v2/handleDeletedDate', bearer, {
    token,
    tenantId,
    entID,
    dateDeleted,
    dateToPermanentlyRemove,
  });
}

export async function updateDailyTimeReportNote(bearer, token, tenantId, dtrDateTime, departmentId, note) {
  const [minTime, maxTime] = _getMinMaxTime(dtrDateTime);

  const payload = {
    value: note,
  };

  return Api.doPut(
    '_ah/api/personapi/v2/updateDailyTimeReportNote',
    bearer,
    {
      token,
      tenantId,
      minTime,
      maxTime,
      targetReportTime: dtrDateTime,
      departmentId,
    },
    payload
  );
}

export async function fetchDailyTimeReportNote(bearer, token, tenantId, dtrDateTime, departmentId) {
  const [minTime, maxTime] = _getMinMaxTime(dtrDateTime);

  return Api.doGet('_ah/api/personapi/v2/fetchDailyTimeReportNote', bearer, {
    token,
    tenantId,
    minTime,
    maxTime,
    departmentId,
  });
}

function _getMinMaxTime(dateTime) {
  let minTime = new Date(dateTime);
  minTime.setHours(0, 0, 0, 0);
  minTime = minTime.getTime();

  let maxTime = new Date(dateTime);
  maxTime.setHours(23, 59, 59, 999);
  maxTime = maxTime.getTime();

  return [minTime, maxTime];
}
