import PropTypes from 'prop-types';

import CustomDialog from '../customDialog';
import DTRTable from '../dtrTable';
import useMobile from '../../hooks/useMobile';

const DailySubmitDialog = (props) => {
  const { isMobile } = useMobile();

  const handleSubmit = () => {
    props.onSubmit();
    props.onClose();
  };

  const createDialogBody = () => {
    if (props.tableData) {
      return <DTRTable data={props.tableData} showOptional={props.showOptional || false} />;
    }
  };

  return (
    <CustomDialog
      fullScreen={isMobile}
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="lg"
      title="Daily Time Report Overview"
      contentText="Confirm the Daily Time Report"
      body={createDialogBody()}
      confirmCallback={handleSubmit}
      confirmButtonMessage="Submit"
      cancelButtonMessage="Edit"
      cancelButtonProps={{
        color: 'neutral',
      }}
      className="test"
    />
  );
};

DailySubmitDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  tableData: PropTypes.array,
  showOptional: PropTypes.bool,
};

export default DailySubmitDialog;
