import PropTypes from 'prop-types';
import { useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import useMobile from '../../hooks/useMobile';

import './index.css';

const columns = [
  { id: 'name', label: 'Name', type: 'text', optional: false, minWidth: 55 },
  { id: 'travel_start_time', label: 'Travel Start', type: 'input', optional: true, minWidth: 55 },
  { id: 'call_time', label: 'Call Time', type: 'input', optional: false, minWidth: 55 },
  { id: 'first_meal_start_time', label: '1st Meal - Start', type: 'input', optional: false, minWidth: 55 },
  { id: 'first_meal_end_time', label: '1st Meal - Finish', type: 'input', optional: false, minWidth: 55 },
  { id: 'second_meal_start_time', label: '2nd Meal - Start', type: 'input', optional: true, minWidth: 55 },
  { id: 'second_meal_end_time', label: '2nd Meal - Finish', type: 'input', optional: true, minWidth: 55 },
  { id: 'wrap_time', label: 'Wrap Time', type: 'input', optional: false, minWidth: 55 },
  { id: 'travel_end_time', label: 'Travel Finish', type: 'input', optional: true, minWidth: 55 },
  { id: 'kit_time', label: 'Kit', type: 'input', optional: true, minWidth: 55 },
  { id: 'upgrade_time', label: 'Upgrade', type: 'input', optional: true, minWidth: 55 },
  { id: 'other_notes', label: 'Other & Notes', type: 'input', optional: true, minWidth: 75 },
];

const DTRTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { isMobile } = useMobile();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createTableHeader = () => {
    return (
      columnsData &&
      columnsData.map((column) => {
        const style = {};

        if (column.minWidth) {
          style.minWidth = column.minWidth;
          style.width = column.minWidth;
        }

        return (
          <TableCell
            align="justify"
            className={column.id === 'name' ? 'thSticky th thReview' : 'th thReview'}
            style={style}
            key={column.id}
          >
            {column.label}
          </TableCell>
        );
      })
    );
  };

  const createTableRows = () => {
    return (
      props.data &&
      props.data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return props.data.find((member) => member.id === row.id);
        })
        .filter((entry) => {
          return entry.call_type !== 'not-in';
        })
        .map((entry, index) => {
          return (
            <TableRow className="tr" hover tabIndex={-1} key={index}>
              {columnsData.map((column) => createCell(column.id, entry))}
            </TableRow>
          );
        })
    );
  };

  const createCell = (id, entry) => {
    return (
      <TableCell className={id === 'name' ? 'tdSticky td tdReview nameField' : 'td tdReview'} key={id}>
        {formatInputValue(entry[id]) || ''}
      </TableCell>
    );
  };

  const formatInputValue = (value) => {
    // eslint-disable-next-line eqeqeq
    if (!value || value === -1) {
      return '';
    }

    return value;
  };

  const columnsData = columns.filter((column) => (props.showOptional ? true : !column.optional));

  return (
    <Paper sx={{ width: '100 % ', overflow: 'hidden', backgroundColor: 'transparent' }}>
      <TableContainer>
        <Table className="table" stickyHeader aria-label="sticky table">
          <TableHead className="thead">
            <TableRow className="tr">{createTableHeader()}</TableRow>
          </TableHead>
          <TableBody className="tbody">{props.data.length > 0 && createTableRows()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={isMobile ? [] : [10, 25, 100]}
        component="div"
        colSpan={2}
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

DTRTable.prototype = {
  data: PropTypes.array.isRequired,
  showOptional: PropTypes.bool,
};

export default DTRTable;
